import daysBetween from "./daysBetween"
import getDateWithSlash from "./getDateWithSlash"


let expiredStatus = {string:"0 Days Left", value:0}

export default function getPackageExpiry(thePayment){

    if(!thePayment) return expiredStatus
    if(thePayment.isTerminated) return expiredStatus

    let startDate = thePayment.createdAt
    startDate = new Date(startDate)

    let finalDate = new Date()
    finalDate.setDate(startDate.getDate() + 365)

    let today = new Date()


    let datesLeft = daysBetween(today, finalDate)
    datesLeft = Math.round(datesLeft)

    let string = `${getDateWithSlash(finalDate)}. ${datesLeft} Days Left`
    let value = datesLeft

    return {value, string}
  }
