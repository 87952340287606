import { useContext } from "react";
import styled from "styled-components";
import Context from "../Context";
import getImageURL from "../controllers/getImageURL";
import getMonthsDuration from "../controllers/getMonthsDuration";
import getTodaysDuration from "../controllers/getTodaysDuration";
import getWeeksDuration from "../controllers/getWeeksDuration";
import FollowButton from "./FollowButton";
import Link from "./Link";
import getDateRange from "../controllers/statLibrary/getDateRange";
import getDateWithSlash from "../controllers/getDateWithSlash";
import toMinsOrHours from "../controllers/toMinOrHours";
import { BsGithub } from "react-icons/bs";
import { useAlert } from "react-alert";
import copy from "copy-to-clipboard";
import openLink from "../controllers/openLink";
import { GrNetwork } from "react-icons/gr";
import { TbWorldWww } from "react-icons/tb";
import ProfilePicture from "./ProfilePicture";
import getLanguageString from "../controllers/getLanguageString";
import countrCodeVsCountry from "../data/countryCodeVsCountry";
import HireProposalSender from "./HireProposalSender";
import { FiSettings } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { RiSettingsLine } from "react-icons/ri";

const Container = styled.div`
  flex-direction: column;
  display: flex;
  gap: 80px;
  width: 100%;
  align-items: center;
  text-align: center;
`;
const Details = styled.div`
  flex-direction: column;
  display: flex;
  gap: 25px;
  width: 100%;
  margin-top: 0px;
  align-items: center;

  text-align: center;
`;
const Name = styled.h1`
  margin: 0;
  width: 100%;
  text-transform: capitalize;
  /* text-align: center; */
  letter-spacing: -0px;
  font-size: 30px;
  margin-bottom: 5px;
  font-family: "Inter", monospace;
  font-weight: 900;
  padding: 0;
`;

const Username = styled.div`
  margin: 0;
  width: 100%;
  /* text-align: center; */
  font-size: 15px;
  margin-top: -25px;

  opacity: 0.5;

  font-family: 'Inter';
  font-weight: 300;
  text-transform: capitalize;
  padding: 0;
`;

const Bio = styled.div`
  margin: 0;
  line-height: 30px;
  width: 100%;
  /* text-align: center; */
  /* font-size: 18px; */

  opacity: 0.5;

  font-family: 'Inter';
  font-weight: 300;
  /* text-transform: capitalize; */
  padding: 0;
`;

const LanguageList = styled.div`
  margin: 0;
  line-height: 30px;
  width: 100%;
  /* text-align: center; */
  /* font-size: 18px; */

  opacity: 0.5;
  text-transform: capitalize;
  font-family: 'Inter';
  font-weight: 300;
  /* text-transform: capitalize; */
  padding: 0;
`;

const StatList = styled.div`
  margin: 0;
  line-height: 30px;
  width: 100%;
  /* text-align: center; */
  /* font-size: 18px; */

  opacity: 0.5;

  font-family: 'Inter';
  font-weight: 300;
  /* text-transform: capitalize; */
  padding: 0;
`;

const DetailsList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 15px;
`;
const Detail = styled.div`
  /* width: calc(38vw / 2.1); */
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  flex-direction: row;
  font-size: 15px;
  background: var(--translucent);
  padding: 15px 25px;

  /* @media (max-width: 1200px) {
    width: calc(62vw / 2.1);
  }

  @media (max-width: 700px) {
    width: ${() =>
    localStorage.getItem("extension-login")
      ? "calc(100vw/2.1)"
      : "calc(90vw / 2.1)"};
  } */

  ${({ isButton, disableIt }) => {
    if (isButton && disableIt)
      return `
      pointer-events:none;
      opacity:0.3;
    `;

    if (isButton)
      return `
      cursor:pointer;
    &:hover{
      background:var(--translucentHard);
    }
    `;
  }}
`;

const StatSection = styled.div``;
const Photos = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  @media (min-width: 800px) {
    gap: 20px;
  }
`;

const Warning = styled.div`
  margin-top: 0;
  padding-top: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 30px;
  opacity: 0.5;
  border-top: 1px solid var(--translucent);
  align-items: center;
`;

const ProfileImage = styled.img`
  height: 100px;
  width: 100px;
  margin-bottom: 20px;
  object-fit: cover;
  border-radius: 50px;
`;

const Links = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: center;
  width: 100%;
  margin: 25px 0;
`;

const LinkButton = styled.div`
  font-size: 25px;
  cursor: pointer;
  opacity: 0.8;
`;

const AllText = styled.div`
  display:flex;
  flex-direction:column;
  font-size:17px ;
  gap:15px;
`
const BorderLine = styled.div`
  height: 1px;
  background-color: var(--translucentHard);
  width: 100%;
`

export default function UserPageTopSection({ user, followStatus }) {
  const { loggedInUser, loggedInUserID, setForm } = useContext(Context);
  const navigate = useNavigate()

  let isPrivateAndNotFollowing = false;

  if (loggedInUserID !== user._id)
    if (user.privacy === "PRIVATE") {
      if (followStatus !== "POSITIVE") {
        isPrivateAndNotFollowing = true;
      }
    }

  let bio = user.bio;

 

  if (user.country && user.state && user.city) {
    if (bio) bio = bio + " · ";
    bio += `${user.city}, ${user.state}, ${countrCodeVsCountry[user.country]}`;
  }

  let actionsButtons = (
    <>
      <FollowButton latestLoggedInUser={loggedInUser} user={user} />
      <Detail
        onClick={openHireProposalSender}
        disableIt={!user.hireable}
        isButton={true}
      >
        Hire / Send Note
      </Detail>
    </>
  );

  if (!loggedInUser) actionsButtons = null;
  if (loggedInUser) if (loggedInUser._id === user._id) actionsButtons = null;


  let allText =  <AllText>


  {bio ? <Bio>{bio}</Bio> : null}

  {bio ?  <BorderLine/> : null}
   

            <StatList>
              Daily Avg: {toMinsOrHours({ unparsedSeconds: user.dailyAvgScore })}
              ・ Last Week:{" "}
              {toMinsOrHours({ unparsedSeconds: user.lastWeekScore })}・ Total:{" "}
              {getTotalTime()}
            </StatList>

          
          {user.languageScore ? (<BorderLine/>) : null}
  
            {user.languageScore ? (
              <LanguageList>{getLanguageString(user)}</LanguageList>
            ) : null}

          </AllText>


  return (
    <Details>
      <ProfilePicture user={user} />

      <Name>{user.name}</Name>
      <Username>@{user.username}</Username>

      

      {isPrivateAndNotFollowing ? null : allText}
    

      {getLinks()}

      <DetailsList>
        <Link href={"/" + user.username + "/followers"}>
          <Detail isButton={true}>Followers: {user.followerCount}</Detail>
        </Link>

        <Link href={"/" + user.username + "/followings"}>
          <Detail isButton={true}>Following: {user.followingCount}</Detail>
        </Link>

        {actionsButtons}
      </DetailsList>
    </Details>
  );

  function openHireProposalSender() {
    setForm({ title: "Hire / Send Note", component: <HireProposalSender user={user} /> });
  }

  function getLinks() {
    if (!user.githubHandle && !user.websiteLink) {
      return null;
    }

    return (
      <Links>
        {user.githubHandle ? (
          <LinkButton
            onClick={openLink("https://github.com/" + user.githubHandle)}
          >
            <BsGithub />
          </LinkButton>
        ) : null}
        {user.websiteLink ? (
          <LinkButton onClick={openLink(user.websiteLink)}>
            <TbWorldWww />
          </LinkButton>
        ) : null}
      </Links>
    );
  }

  function getTotalTime() {
    let totalTime = 0;

    // for (let slashDate in user.stat) {
    //   let dateDoc = user.stat[slashDate];
    //   if (!dateDoc) continue;
    //   totalTime += dateDoc.totalDurationInSecs;
    // }

    return toMinsOrHours({ unparsedSeconds: user.totalDurationInSecs });
  }

  function caclulateDailyAverage() {
    let totalTime = 0;

    let count = 0;

    for (let dateSlash in user.stat) {
      count++;
      let dateDoc = user.stat[dateSlash];
      totalTime += dateDoc.totalDurationInSecs;
    }
    // return JSON.stringify({ dataPoints, a: startDate.toString() });

    let avgVal = totalTime / count;
    return toMinsOrHours({ unparsedSeconds: avgVal });
  }
}
