import WithHeader from "./WithHeader";
import { FiSearch } from "react-icons/fi";
import { serverLine } from "../controllers/serverLine";
import LoadingSection from "./LoadingSection";

import styled from "styled-components";
import { useContext, useState } from "react";
import UserBoxSmall from "./UserBoxSmall";
import { useAlert } from "react-alert";
import Leaderboard from "./Leaderboard";
import FollowerOrFollowingBox from "./FollowerOrFollowingBox";
import Context from "../Context";

const Container = styled.div`
  width: 100%;
`;
const SearchBoxContainer = styled.div`
  display: flex;
  margin-bottom: 50px;
  flex-direction: row;
  width: 100%;
  overflow: hidden;
  border-radius: 15px;
  background-color: var(--translucent);
  height: 50px;
`;

const SearchInput = styled.input`
  height: 50px;
  display: flex;
  color: var(--color);
  padding-left: 25px;
  flex: 1;
  outline: none;
  border: none;
  border: none;
  background-color: transparent;
`;

const SearchButton = styled.button`
  height: 50px;
  cursor: pointer;
  color: var(--color);
  padding-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  border: none;
  font-size: 15px;
  background-color: transparent;

  &:hover {
    background: var(--color);
    color: var(--bgColor);
  }
`;
const SearchResult = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export default function Search() {
  const { loggedInUserID } = useContext(Context);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchRes, setSearchResult] = useState([]);
  const [loading, setLoading] = useState(null);
  const alert = useAlert();

  if (!loggedInUserID) return "Login to continue";

  if (loading) return <LoadingSection />;

  let resComp = <SearchResult>{renderResult()}</SearchResult>;

  if (!searchQuery) resComp = <Leaderboard />;

  return (
    <Container>
      <SearchBoxContainer>
        <SearchInput
          placeholder="Search users by username, name or email"
          onKeyUp={detectEnter}
          onChange={updateQuery}
          value={searchQuery}
        />
        <SearchButton
          onClick={() => {
            doSearch();
          }}
        >
          <FiSearch />
        </SearchButton>
      </SearchBoxContainer>
      {resComp}
    </Container>
  );

  function renderResult() {
    if (!searchRes) return [];
    return searchRes.map((item) => {
      return <FollowerOrFollowingBox key={item.id} user={item} />;
    });
  }

  function detectEnter(e) {
    if (e.key === "Enter" || e.keyCode === 13) {
      doSearch(e.target.value);
    }
  }

  function updateQuery(e) {
    console.log(e.target.value);
    setSearchQuery(e.target.value);
  }

  function doSearch(newVal) {
    setLoading(true);
    let query = newVal ? newVal : searchQuery;
    console.log(query, searchQuery);
    serverLine
      .get("/search/?query=" + query)
      .then((data) => {
        setSearchResult(data);
        setLoading(false);
      })
      .catch((e) => {
        alert(e.message);
      });
  }
}
