import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Context from "../Context";
import ProfilePostsGrid from "./ProfilePostsGrid";
import Stat from "./Stat";
import UserPageTopSection from "./UserPageTopSection";
import { useParams } from "react-router-dom";
import { serverLine } from "../controllers/serverLine";
import LoadingSection from "./LoadingSection";
import StatOfUser from "./StatOfUser";
import getXAxisForHozGraph from "../controllers/statLibrary/getXAxisForHozGraph";
import getDateRange from "../controllers/statLibrary/getDateRange";
import getDateWithSlash from "../controllers/getDateWithSlash";
import toMinsOrHours from "../controllers/toMinOrHours";
import getFollowStatus from "../controllers/getFollowStatus";

const Container = styled.div`
  flex-direction: column;
  display: flex;
  gap: 80px;
  width: 100%;
  align-items: center;
  text-align: center;
`;
const Details = styled.div`
  flex-direction: column;
  display: flex;
  gap: 25px;
  margin-top: 50px;
  align-items: center;
  text-align: center;
`;
const Name = styled.h1`
  margin: 0;
  padding: 0;
`;
const SmallDetails = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
`;
const Detail = styled.div``;
const StatSection = styled.div``;
const Photos = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  @media (min-width: 800px) {
    gap: 20px;
  }
`;

const Warning = styled.div`
  margin-top: 0;
  padding-top: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 30px;
  opacity: 0.5;
  border-top: 1px solid var(--translucent);
  align-items: center;
`;

export default function UserPage() {
  const { loggedInUserID, updateLoggedInUser, loggedInUser } =
    useContext(Context);

  const [error, setError] = useState(null);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  let { username } = useParams();

  useEffect(() => {
    if (loggedInUserID) {
      updateLoggedInUser();
    }

    setLoading(true);
    serverLine
      .get(`/profile/?username=${username}`)
      .then((newData) => {
        setData(newData);
        setLoading(false);
      })
      .catch(setError);
  }, [username, loggedInUserID]);

  if (error) return error.message;

  if (loading) return <LoadingSection />;

  let { user } = data;

  let followStatus = getFollowStatus(loggedInUser, user._id);

  let content = (
    <>
      <StatOfUser userStat={user.stat} user={user} />
    </>
  );

  if (loggedInUserID !== user._id)
    if (user.privacy === "PRIVATE") {
      if (followStatus !== "POSITIVE")
        content = <Warning>This Account is private</Warning>;
    }

  return (
    <Container>
      <UserPageTopSection followStatus={followStatus} user={user} />
      {content}
    </Container>
  );
}
