import Link from "./Link";
import { useContext } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import Context from "../Context";
import { serverLine } from "../controllers/serverLine";

import LoadingSection from "./LoadingSection";

import { useState } from "react";

import PostsSection from "./PostsSection";

import { Title } from "./UtilityComponents";
import VerticalBarGraph from "./statLibrary/VerticalBarGraph";
import HorizontalBarGraph from "./statLibrary/HorizontalBarGraph";
import getDateWithSlash from "../controllers/getDateWithSlash";
import toMinsOrHours from "../controllers/toMinOrHours";
import hozStatDataProcesssor from "../controllers/hozStatDataProcessor";
import ProjectSpecificStat from "./ProjectSpecificStat";
import TimeStat from "./statLibrary/TimeStat";
import processLangName from "../controllers/processLangName";

const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
  gap: 25px;
`;

const Tab = styled.button`
  background-color: rgba(255, 255, 225, 0);
  border: 1px solid;
  color: var(--color);
  padding: 10px 25px;
  border-radius: 25px;
  cursor: pointer;
  opacity: 0.7;

  &:hover {
    background: var(--color);
    color: var(--bgColor);
  }

  ${({ highlight }) => {
    if (highlight) {
      return `
        background-color:var(--color);
        color: var(--bgColor);
        opacity:1;
        `;
    }
  }}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

export default function StatOfUser({ userStat, user }) {
  const { setForm, loggedInUserID } = useContext(Context);
  if (!user) return;

  if (!userStat) return;
  // userStat = userStat.stat;

  let name = user.name;
  name = name.split(" ")[0];

  let hideProjects = false;

  if (loggedInUserID) {
    if (loggedInUserID !== user._id) {
      if (user.hideProjectData) hideProjects = true;
    }
  } else {
    if (user.hideProjectData) hideProjects = true;
  }

  return [
    <HorizontalBarGraph
      title={`Stat`}
      rawData={userStat}
      dataProcessor={hozStatDataProcesssor}
    />,
    <TimeStat title={`Time Stat`} rawData={userStat} />,
    hideProjects ? null : (
      <VerticalBarGraph
        title={`Projects`}
        rawData={userStat}
        dataProcessor={verticalDataProcessorProjectBased}
      />
    ),
    <VerticalBarGraph
      title={`Languages`}
      rawData={userStat}
      dataProcessor={verticalDataProcessorLanguageBased}
    />,
  ];

  function verticalDataProcessorLanguageBased({
    dataPoints,
    startDate,
    timeSpanType,
    rawData,
  }) {
    let proj = {};

    let date = new Date(startDate.toString());

    if (timeSpanType === "EVER") {
      for (let dateString in rawData) {
        processDate(dateString);
      }
    } else {
      for (let i = 0; i < dataPoints; i++) {
        let slashDate = getDateWithSlash(date);
        processDate(slashDate);
        date.setDate(date.getDate() + 1);
      }
    }

    function processDate(slashDate) {
      if (rawData[slashDate]) {
        let item = rawData[slashDate];
        if (item.sessionData) {
          for (let sessionID in item.sessionData) {
            let session = item.sessionData[sessionID];

            if (session.languageData) {
              for (let langID in session.languageData) {
                let langObj = session.languageData[langID];
                processLangObj(langID, langObj);
              }
            }
          }
        }
      }
    }

    function processLangObj(languageID, langObj) {
      if (proj[languageID]) {
        proj[languageID].value += langObj.durationInSecs;
        proj[languageID].label = `${toMinsOrHours({
          unparsedSeconds: proj[languageID].value,
        })} . ${languageID}`;
      } else {
        proj[languageID] = {
          value: langObj.durationInSecs,
          label: `${toMinsOrHours({
            unparsedSeconds: langObj.durationInSecs,
          })} . ${processLangName(languageID)}`,
        };
      }
    }

    return proj;
  }

  function verticalDataProcessorProjectBased({
    dataPoints,
    startDate,
    timeSpanType,
    rawData,
  }) {
    let proj = {};

    let date = new Date(startDate.toString());

    if (timeSpanType === "EVER") {
      for (let dateString in rawData) {
        processDate(dateString);
      }
    } else {
      for (let i = 0; i < dataPoints; i++) {
        let slashDate = getDateWithSlash(date);
        processDate(slashDate);
        date.setDate(date.getDate() + 1);
      }
    }

    function processDate(slashDate) {
      if (rawData[slashDate]) {
        let item = rawData[slashDate];
        if (item.sessionData) {
          for (let sessionID in item.sessionData) {
            let session = item.sessionData[sessionID];

            if (!session.projectID) session.projectID = "0";

            if (session.projectID) {
              let projectID = session.projectID;

              let projectName = "Untitled";

              if (user.projects) {
                if (projectID.toString() === "0") {
                  projectName = "Miscellaneous";
                } else {
                  let projectNameObj = user.projects[projectID];
                  if (projectNameObj) {
                    projectName = projectNameObj.name;
                  }
                }
              }

              if (proj[projectID]) {
                proj[projectID].value += session.durationInSecs;
                proj[projectID].label = `${toMinsOrHours({
                  unparsedSeconds: proj[projectID].value,
                })} . ${projectName}`;
              } else {
                proj[projectID] = {
                  onClick: () => {
                    setForm({
                      title: projectName + " Project Stat",
                      component: (
                        <ProjectSpecificStat
                          rawData={rawData}
                          projectID={projectID}
                          projectName={projectName}
                        />
                      ),
                    });
                  },
                  value: session.durationInSecs,
                  label: `${toMinsOrHours({
                    unparsedSeconds: session.durationInSecs,
                  })} . ${projectName}`,
                };
              }
            }
          }
        }
      }
    }

    return proj;
  }
}
