import Link from "../Link";
import styled from "styled-components";

import getStatColor from "../../controllers/getStatColor";
import getMaxTime from "../../controllers/getMaxTime";
import toMinsOrHours from "../../controllers/toMinOrHours";
import getDateString from "../../controllers/getDateString";
import { AiOutlineEdit } from "react-icons/ai";
import shadeColor from "../../controllers/shadeColor";
import Select from "react-select";
import { useEffect, useState } from "react";
import CustomSelect from "../CustomSelect";

const Padding = styled.div`
  width: 100%;
`;

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 25px;
`;

const Title = styled.div`
  font-size: 21px;
  font-weight: 300;
`;

const Columns = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px 25px;
  background-color: var(--translucent);
  border-radius: 15px;
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`;

const ColorfulBar = styled.div`
  height: 30px;
  background: steelblue;
  border-radius: 5px 25px 25px 5px;
`;

const Label = styled.div`
  opacity: 0.5;
`;

export default function GraphDurationSelect({ value, setValue, options }) {
  return (
    <CustomSelect
      defaultValue={value ? value : options[0]}
      onChange={setValue}
      options={options}
    />
  );
}
