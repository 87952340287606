

import { styled } from "styled-components"
import Link from "./Link"
import { useNavigate } from "react-router-dom"
import { useContext, useEffect } from "react"
import Context from "../Context"
import getUpgradeLink from "../controllers/getUpgradeLink"
import { serverLine } from "../controllers/serverLine"
import getCurrentPackage from "../controllers/getCurrentPackage"
import LoadingSection from "./LoadingSection"

const Container = styled.div`
    display:flex;
    flex-direction:column;
    gap:0;
    align-items:center;
    border-radius:15px;
    padding:50px;
`

const Title = styled.h3``

const Button = styled.div`
cursor: pointer;
/* flex: 1; */
padding: 15px 25px;
background: var(--translucent);
border-radius: 15px;

display: flex;
justify-content: center;
align-items: center;
&:hover {
  background: var(--color);
  color: var(--bgColor);
}
`

export default function ExtensionWaitingForPurchase(){

    const navigate = useNavigate()
    const {setForm} = useContext(Context)

    useEffect(()=>{
        checkEveryfiveSeconds()
    },[])

    return <Container>
            <Title>Waiting For Purchase</Title>
            <LoadingSection/>
            <Button onClick={cancelWaiting}>Cancel</Button>
        </Container>

    function checkEveryfiveSeconds(){
        window.checkEveryfiveSecondsInterval = setInterval(async ()=>{
            let newLoggedInUser = await serverLine.get("/logged-in-user")
            
            let currentPackage = getCurrentPackage(newLoggedInUser)

            if(currentPackage){
                window.location = "/upgrade"
            }
            
        },5000)
    }

    function cancelWaiting(){
        window.clearInterval(window.checkEveryfiveSecondsInterval)
        setForm(null)
      
    }

}