import styled from "styled-components";

import LogoSVG from "./LogoSVG";

const BrandContainerMain = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

const H1 = styled.h1`
  font-weight: 900;
  margin: 0;
  margin-top: -3px;
  font-size: 21px;
  width: auto;
  width: 100%;
  /* font-family: "Source Code Pro", monospace; */
  /* letter-spacing: -1px; */
  @media (min-width: 920px) {
    font-size: 21px;
  }
`;

export default function BrandContainer({ style }) {
  return (
    <BrandContainerMain style={style}>
      <LogoSVG />
      <H1>Paratime</H1>
    </BrandContainerMain>
  );
}
