import { styled } from "styled-components"
import Link from "./Link"
import { useNavigate } from "react-router-dom"
import { useContext } from "react"
import Context from "../Context"
import getUpgradeLink from "../controllers/getUpgradeLink"
import upgradeExtension from "../controllers/upgradeExtension"

const Container = styled.div`
    display:flex;
    flex-direction:column;
    gap:0;
    align-items:center;
    border-radius:15px;
    padding:50px;
`

const Title = styled.h3``

const Button = styled.div`
cursor: pointer;
/* flex: 1; */
padding: 15px 25px;
background: var(--translucent);
border-radius: 15px;

display: flex;
justify-content: center;
align-items: center;
&:hover {
  background: var(--color);
  color: var(--bgColor);
}
`

export default function GetPremiumToSeeMoreItems({toGold}){

    const navigate = useNavigate()
    const {setForm} = useContext(Context)

    return <Container>
        <Title>Get {toGold?"Gold Membership":"Premium"} to see more items</Title>
     
        <Button onClick={getPremium}>Get Premium</Button>
       
    </Container>

function getPremium(){
    let extLogin = localStorage.getItem("extension-login")

    if(extLogin){
        upgradeExtension(setForm)
    }else{
        navigate("/upgrade")
        setForm(null)
    }
}
}