import "./App.css";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import Boilerplate from "./components/Boilerplate";
import Home from "./components/Home";
import UserPage from "./components/UserPage";
import Search from "./components/Search";
import PrivacyPolicy from "./components/PrivacyPolicy";
import SessionPage from "./components/SessionPage";
import Settings from "./components/Settings";
import Notifications from "./components/Notifications";
import EditTags from "./components/EditTags";
import SendAuthCode from "./components/SendAuthCode";
import PostPage from "./components/PostPage";
import TakeBreak from "./components/TakeBreak";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AlertTemplate from "./components/AlertTemplate";
import WithHeader from "./components/WithHeader";
import ShowFollowingsOrFollowing from "./components/ShowFollowingsOrFollowings";
import CreateTimer from "./components/CreateTimer";
import ExtensionLogin from "./components/ExtensionLogin";
import EditProfilePage from "./components/EditProfilePage";
import Leaderboard from "./components/Leaderboard";
import LeaderboardPage from "./components/LeaderboardPage";
import UpgradePage from "./components/UpgradePage";
import StripePaymentSuccess from "./components/StripePaymentSuccess";
import LoginAndUpgrade from "./components/LoginAndUpgrade";
import PurchaseNewPlan from "./components/PurchaseNewPlan";
import FeedPage from "./components/FeedPage";

const options = {
  position: positions.BOTTOM_CENTER,
  timeout: 3000,
  containerStyle: {
    zIndex: 900,
    pointerEvents: "unset",
  },
  offset: "30px",
  transition: transitions.SCALE,
};

function App() {
  return (
    <AlertProvider template={AlertTemplate} {...options}>
      <BrowserRouter>
        <Boilerplate>
          <WithHeader>
            <Routes>
              <Route path="/">
                <Route index element={<Home />} />
                <Route path="feed" element={<FeedPage />} />
                <Route path="edit-profile" element={<EditProfilePage />} />
                <Route path="extension-login" element={<ExtensionLogin />} />
                <Route path="create-timer" element={<CreateTimer />} />
                <Route
                  path=":username/followers"
                  element={<ShowFollowingsOrFollowing type="Followers" />}
                />
                <Route
                  path=":username/followings"
                  element={<ShowFollowingsOrFollowing type="Followings" />}
                />

                <Route path="profile/:username" element={<UserPage />} />

                <Route path="post/:postID" element={<PostPage />} />
                <Route path="auth-redirect" element={<SendAuthCode />} />
                <Route path="edit-projects" element={<EditTags />} />
                <Route path="notifications" element={<Notifications />} />
                <Route path="privacy-policy" element={<PrivacyPolicy />} />
                <Route path="search" element={<Search />} />
                <Route path="session" element={<SessionPage />} />
                <Route path="settings" element={<Settings />} />
                <Route path="take-break" element={<TakeBreak />} />
                <Route path="upgrade" element={<UpgradePage />} />
                <Route path="stripe-payment-success" element={<StripePaymentSuccess />} />
                <Route path="login-and-upgrade" element={<LoginAndUpgrade />} />
                <Route path="new-plan" element={<PurchaseNewPlan />} />

                <Route path="leaderboard" element={<LeaderboardPage />} />
                <Route path=":username" element={<UserPage />} />
                <Route path="*" element={"404"} />
              </Route>
            </Routes>
          </WithHeader>
        </Boilerplate>
      </BrowserRouter>
    </AlertProvider>
  );
}

export default App;
