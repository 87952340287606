import { useContext } from "react";
import styled from "styled-components";
import Context from "../Context";
import { useEffect, useState } from "react";
import LoadingSection from "./LoadingSection";
import { serverLine } from "../controllers/serverLine";

const Container = styled.div`
  display: flex;
  gap: 25px;
  flex-direction: column;
  color: var(--color);
`;

const Tags = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
const Input = styled.input`
  background-color: var(--translucent);
  border: none;
  padding: 15px 25px;
  color: var(--color);
  border-radius: 25px;
`;

const SaveButton = styled.button`
  padding: 15px 20px;
  color: var(--color);
  cursor: pointer;
  border-radius: 25px;
  border: none;
  background-color: var(--translucent);
`;

export default function EditTags() {
  const { loggedInUser } = useContext(Context);
  const [newTags, setNewTags] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (loggedInUser) {
      setNewTags(loggedInUser.projects);
    }
  }, [loggedInUser]);

  if (loading) return <LoadingSection />;

  if (!newTags) return "No projects to edit";
  if (!newTags.length) return "No projects to edit";

  return (
    <Container>
      <h1>Edit Tags</h1>
      <Tags>{getTags()}</Tags>
      <SaveButton onClick={save}>Save</SaveButton>
    </Container>
  );

  function getTags() {
    let toRender = [];

    if (!newTags) return [];

    for (let projectID in newTags) {
      toRender.push(
        <Input
          onChange={updateTag(projectID)}
          value={newTags[projectID].name}
        />
      );
    }

    return toRender;
  }

  function updateTag(projectID) {
    return (e) => {
      let theNewTags = { ...newTags };

      theNewTags[projectID].name = e.target.value;

      setNewTags(theNewTags);
    };
  }

  async function save() {
    setLoading(true);
    await serverLine.patch("/profile", { changes: { allProjects: newTags } });
    window.navigate("/profile/" + loggedInUser.username);
  }
}
