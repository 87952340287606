import { serverLine } from "../controllers/serverLine";
import LoadingSection from "./LoadingSection";

import styled from "styled-components";
import { useContext, useEffect, useState } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";
import Context from "../Context";
import { useAlert } from "react-alert";

export default function ExtensionLogin() {
  const [searchParams] = useSearchParams();
  const alert = useAlert();

  useEffect(() => {
    let userID = searchParams.get("userID");
    let token = searchParams.get("token");
    let bgColor = searchParams.get("bgColor");
    let color = searchParams.get("color");

    if (userID && token) {
      // alert.show(`USERID:${userID}  TOKEN: ${token}`);
      localStorage.setItem("paratime-user-id", userID);
      localStorage.setItem("paratime-user-token", token);
      localStorage.setItem("extension-login", true);

      if (bgColor) {
        bgColor = "#" + bgColor;
        color = "#" + color;
        let translucent = "rgba(0,0,0,0.1)";
        let translucentHard = "rgba(0,0,0,0.2)";

        let luma = getLuma(bgColor);

        if (luma < 100) {
          translucent = "rgba(255,255,255,0.05)";
          translucentHard = "rgba(255,255,255,0.2)";
        }

        localStorage.setItem(
          "vscode-extension-colors",
          JSON.stringify({
            color,
            bgColor,
            bgColor2: bgColor,
            translucent,
            translucentHard,
          })
        );
      }

      window.location = "/";
    }
  }, []);

  // return `${searchParams.get("userID")}  ${searchParams.get("token")}`;

  return <LoadingSection />;

  function getLuma(c) {
    var c = c.substring(1); // strip #
    var rgb = parseInt(c, 16); // convert rrggbb to decimal
    var r = (rgb >> 16) & 0xff; // extract red
    var g = (rgb >> 8) & 0xff; // extract green
    var b = (rgb >> 0) & 0xff; // extract blue

    var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
    return luma;
  }
}
