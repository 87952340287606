import Link from "./Link";
import { useContext } from "react";
import styled from "styled-components";
import Context from "../Context";
import { useState } from "react";
import StatOfUser from "./StatOfUser";
import { useNavigate } from "react-router-dom";
import { MdAdd } from "react-icons/md";
import ProfilePicture from "./ProfilePicture";
import shortenTheName from "../controllers/shortenTheName";

const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
  gap: 20px;
`;

const Tab = styled.button`
  background-color: transparent;
  border: 1px solid var(--translucentHard);
  color: var(--color);
  display: flex;
  flex-direction: row;
  gap: 7px;
  padding: 10px 25px;
  border-radius: 10px;
  cursor: pointer;
  opacity: 0.8;
  font-size: 15px;
  align-items: center;
  transition: 0.25s ease-in;

  &:hover {
    transform: scale(0.9);
  }

  ${({ highlight }) => {
    if (highlight) {
      return `
        background-color:var(--translucent);
        color: var(--color);
        border:none;
        opacity:1;
        `;
    }
  }}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

const TabIcon = styled.div`
  font-size: 18px;
  margin-bottom: -2px;
`;

const Message = styled.div`
  background: var(--translucent);
  padding: 25px;
  border-radius: 15px;
`;

const TabText = styled.div``;

const Title = styled.h2`
  /* margin-bottom: -15px; */
  text-transform: capitalize;
  margin: 0;
  padding: 0;
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
`;

export default function StatOfEachUser({ statOfMeAndFriends }) {
  let [selectedUser, setSelectedUser] = useState(null);
  const { loggedInUser } = useContext(Context);
  const navigate = useNavigate();

  if (!loggedInUser) return;

  if (!selectedUser) selectedUser = loggedInUser._id;

  const tabs = [];

  for (let userID in statOfMeAndFriends) {
    if (userID === selectedUser) continue;
    tabs.push(
      <Tab
        onClick={() => {
          setSelectedUser(userID);
        }}
        highlight={userID === selectedUser}
      >
        {statOfMeAndFriends[userID].userData.name}
      </Tab>
    );
  }

  tabs.push(
    <Tab
      onClick={() => {
        navigate("/search");
      }}
    >
      <TabIcon>
        <MdAdd />
      </TabIcon>
      <TabText>Competitor</TabText>
    </Tab>
  );

  let user = statOfMeAndFriends[selectedUser];
  if (!user) return null;

  let title = `${shortenTheName(user.userData.name, 15)}`;

  return (
    <Container>
      <UserInfo>
        <ProfilePicture size="50px" user={user.userData} />
        <Title>{title}</Title>
      </UserInfo>

      <Tabs>{tabs}</Tabs>
      {statOfMeAndFriends[selectedUser] ? (
        <StatOfUser
          userStat={statOfMeAndFriends[selectedUser].stat}
          user={statOfMeAndFriends[selectedUser].userData}
        />
      ) : (
        <Message>No data to show</Message>
      )}
    </Container>
  );
}
