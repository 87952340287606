import { styled } from "styled-components"
import getImageURL from "../../controllers/getImageURL"
import ProfilePicture from "../ProfilePicture"
import shortenTheName from "../../controllers/shortenTheName"
import { AiOutlineCheck } from "react-icons/ai"



const Container = styled.div`
  display: flex;
  flex-direction:row;
  justify-content: space-between;
`

const UserBox = styled.div`
  display: flex;
  flex-direction:row;
  align-items: center;
  gap:15px;
`


const UserBoxTitle = styled.div`

`

const Button = styled.div`
  padding: 10px 20px;
  border: 1px solid var(--translucentHard);
  color: var(--color);
  background: transparent;
  font-size: 15px;
  cursor: pointer;
  border-radius: 10px;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 10px;
  transition: 0.25s ease-out;
  &:hover {
    transform: scale(0.9);
  }
`;

const RoundButton = styled(Button)`
  padding: 0;
  height: 35px;
  width: 35px;
  border-radius: 50px;
  justify-content: center;
  align-items: center;

  ${({ highlight }) => {
    if (highlight)
      return `
      background:var(--color);
      color:var(--bgColor);
    `;
  }}
`;

const ButtonText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function SelectUserBox({userdata, list, setList}){


  let selected = list.includes(userdata._id)


  return <Container>
    <UserBox>
      <ProfilePicture size={'30px'} user={userdata} />
      <UserBoxTitle>{ shortenTheName( userdata.name)}</UserBoxTitle>
    </UserBox>

    <RoundButton
            highlight={selected}
            onClick={selectDeselect}
          >
            <ButtonIcon>
              <AiOutlineCheck />
            </ButtonIcon>
          </RoundButton>



  </Container>


  function selectDeselect(){
    if(selected){
      let newList = [...list]
      newList.splice(list.indexOf(userdata._id),1)
      setList(newList)
    }else{
      let newList = [...list]
      newList.push(userdata._id)
      setList(newList)
    }
  }
}