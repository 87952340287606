import { serverLine } from "../controllers/serverLine";
import LoadingSection from "./LoadingSection";

import styled from "styled-components";
import { useContext, useEffect, useState } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";
import Context from "../Context";
import { useAlert } from "react-alert";

export default function LoginAndUpgrade() {
  const [searchParams] = useSearchParams();
  const alert = useAlert();

  useEffect(() => {
    let userID = searchParams.get("userID");
    let token = searchParams.get("token");
  
    if (userID && token) {
      localStorage.setItem("paratime-user-id", userID);
      localStorage.setItem("paratime-user-token", token);
      window.location = "/upgrade";
    }
  }, []);

  return <LoadingSection />;
}
