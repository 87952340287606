import processLangName from "./processLangName";
import toMinsOrHours from "./toMinOrHours";

export default function getLanguageString(user, max = 3) {
  let str = "";

  if (!user.languageScore) return "";

  let list = user.languageScore;
  if (!list[0]) return "";

  let firstItem = list[0];
  str = `${processLangName(firstItem.languageID)} (${toMinsOrHours({
    unparsedSeconds: firstItem.time,
  })})`;

  list = list.slice(1, max);
  for (let item of list) {
    str += `・ ${processLangName(item.languageID)} (${toMinsOrHours({
      unparsedSeconds: item.time,
    })})`;
  }

  return str;
}
