import styled from "styled-components";

const Title = styled.h2`
  padding: 0;
  margin: 0;
  margin-bottom: 0;
  font-weight: 300;
  opacity: 0.7;
  margin: 0;
`;

const SubTitle = styled.div`
  opacity: 0.5;
  font-weight: 300;
  margin-bottom: 25px;
`;

export { Title, SubTitle };
