import getMonthLength from "../getMonthLength";
import getMonthName from "../getMonthName";

function getCusomtDayNumber(val){

  //0 is sunday but we want to start from monday, so we return 6
  if(val == 0) return 6
  return val - 1
}

export default function getDateRange({ timeSpanType, pagination, graphType }) {
  let startDate = null;
  let rangeLabel = null;
  let dataPoints = null;

  if (timeSpanType === "DATE") {
    dataPoints = 1;
    let startingPoint = new Date();
    startingPoint.setDate(startingPoint.getDate() - dataPoints * pagination);

    startDate = startingPoint;
    rangeLabel = `${startingPoint.getDate()} ${getMonthName(startingPoint)}`;
    if (pagination == 0) rangeLabel = "Today";
  } else if (timeSpanType === "WEEK") {

    //From Monday to sunday
    dataPoints = 7;
    let startingPoint = new Date();
    let dayNumber = startingPoint.getDay();
    startingPoint.setDate(startingPoint.getDate() - getCusomtDayNumber(dayNumber));
    startingPoint.setDate(startingPoint.getDate() - dataPoints * pagination);

    let lastDay = new Date(startingPoint.toString());
    lastDay.setDate(lastDay.getDate() + 6);

    startDate = startingPoint;
    rangeLabel = `${startingPoint.getDate()} ${getMonthName(
      startingPoint
    )} - ${lastDay.getDate()} ${getMonthName(lastDay)}`;


  } else if (timeSpanType === "MONTH") {
    let startingPoint = new Date();

    startingPoint.setDate(
      startingPoint.getDate() - (startingPoint.getDate() - 1)
    );
    startingPoint.setMonth(startingPoint.getMonth() - pagination);

    dataPoints = getMonthLength(startingPoint);
    startDate = startingPoint;
    rangeLabel = `${getMonthName(
      startingPoint
    )} ${startingPoint.getFullYear()}`;
  } else if (timeSpanType === "YEAR") {
    let startingPoint = new Date();

    startingPoint = new Date(`1/1/${startingPoint.getFullYear()}`);

    startingPoint.setFullYear(startingPoint.getFullYear() - pagination);

    dataPoints = 12;
    startDate = startingPoint;
    rangeLabel = startingPoint.getFullYear();

    if (graphType === "VERTICAL") dataPoints = 365;
  } else if (timeSpanType === "EVER") {
    let startingPoint = new Date();

    startingPoint.setFullYear(startingPoint.getFullYear() - 6);

    startingPoint.setFullYear(startingPoint.getFullYear() - 7 * pagination);

    let endPoint = new Date(startingPoint.toString());

    endPoint.setFullYear(endPoint.getFullYear() + 6);

    dataPoints = 7;
    startDate = startingPoint;
    rangeLabel = `${startingPoint.getFullYear()} - ${endPoint.getFullYear()}`;

    if (graphType === "VERTICAL") dataPoints = 365 * 7;
  }

  return { startDate, rangeLabel, dataPoints, timeSpanType };
}

//let xAxis = getXAxis({ timeSpanType, startDate, dataPoints });
