import styled from "styled-components";
import getImageURL from "../controllers/getImageURL";
import Link from "./Link";
import toMinsOrHours from "../controllers/toMinOrHours";
import { serverLine } from "../controllers/serverLine";
import {
  AiOutlineHeart,
  AiFillHeart,
  AiOutlineLock,
  AiOutlineArrowRight,
} from "react-icons/ai";
import { useContext } from "react";
import Context from "../Context";
import { useState } from "react";
import { TbExternalLink } from "react-icons/tb";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import getDateString from "../controllers/getDateString";
import { BsThreeDots } from "react-icons/bs";
import AccomplishmentAdder from "./AccomplishmentAdder";
import ConfettiInstance from "./ConfettiInstance";

const Container = styled.div`
  height: auto;
  border-radius: 5px;
  width: 100%;
  overflow: hidden;

  /* background-color: var(--translucent); */
  display: flex;
  flex-direction: column;

  position: relative;

  background-color: var(--translucent);
  color: var(--color);
  padding: 25px;
  gap: 50px;
  &:hover {
    overflow: visible;
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  align-items: start;
`;

const TheImagev3 = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  object-fit: cover;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
`;

const TopText = styled.div`
  display: flex;
  position: relative;
  gap: 15px;
  flex-wrap: wrap;
  text-transform: capitalize;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--translucentHard);
  /* :after {
    position: absolute;
    content: "";
    background: var(--bgColor);
    bottom: 0;
    left: 0;
    opacity: 0.05;
    height: 1px;
    width: 100%;
  } */
`;

const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  gap: 15px;
  padding: 0;
`;
const LikeIcon = styled.div``;

const LikeCount = styled.div``;

const ActionButton = styled.button`
  border: none;
  position: relative;
  /* height: 50px;
  width: 50px; */
  font-size: 20px;
  /* color: var(--bgColor); */
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 10px 25px;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid var(--translucentHard);
  color: var(--color);

  &:hover {
    background-color: var(--bgColor);
    color: var(--color);
  }

  ${({ disable }) => {
    if (disable)
      return `
      pointer-events:none;
      opacity:0.5;
      cursor:unset;
    
    `;
  }}
`;

const StringSpan = styled.div`

`;

const ShowIndex = styled.div`
  height: 45px;

  width: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border-left: 1px solid var(--translucent);
  border-right: 1px solid var(--translucent);
`;

const TheDot = styled.div``;

const SubItem = styled.div`
  font-size: 18px;
  text-align: left;
  flex-direction: row;
  display: flex;
  gap: 5px;
`;

const Heading = styled.div`
  opacity: 0.5;
`;
const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
`;

export default function PostBox({ item }) {
  const [likeStatus, setLikeStatus] = useState(item.likeStatus);
  const [likeCount, setLikeCount] = useState(
    item.likeCount ? item.likeCount : 0
  );
  const [doExplosion, setDoExplosion] = useState(false)
  const { setForm } = useContext(Context);

  // if (item.archived) return null;

  const { loggedInUserID, promptLogin } = useContext(Context);

  let author = item.author ? item.author : {};

  if(!item.accomplishments) item.accomplishments = []
  if(!item.learnings) item.learnings = []

  if (!item.accomplishments.length && !item.learnings.length) return null;


  let date = getDateString(item.date);


  let topTextContent = (
    <>
      <Link href={"/" + author.username}>
        <StringSpan style={{ textDecoration: "underline", cursor: "pointer" }}>
          {author.username}
        </StringSpan>
      </Link>
      {/* <TheDot>·</TheDot> */}
      {/* <StringSpan>{"Clocked " + totalDuration}</StringSpan> */}
      <TheDot>·</TheDot>
      <StringSpan>{"On " + date}</StringSpan>
    </>
  );

  let theList = [];
  let theList2 = [];

  for (let subItem of item.accomplishments) {
    theList.push(
      <SubItem>
        {subItem.secretTitle ? <AiOutlineLock /> : <AiOutlineArrowRight />}
        {subItem.title}
      </SubItem>
    );
  }

  if (item.learnings) {
    for (let subItem of item.learnings) {
      theList2.push(
        <SubItem>
          {subItem.secretTitle ? <AiOutlineLock /> : <AiOutlineArrowRight />}
          {subItem.title}
        </SubItem>
      );
    }
  }

  return (
    <Container>
      <TopText>{topTextContent}</TopText>

      {theList.length ? (
        <Main>
          <Heading>Accomplishments</Heading>
          <List> {theList} </List>
        </Main>
      ) : null}
      {theList2.length ? (
        <Main>
          <Heading>Learnings</Heading>
          <List>{theList2}</List>
        </Main>
      ) : null}

      <ActionButtons>
        <ActionButton
          disable={item.authorUserID === loggedInUserID}
          onClick={likePost}
        >
          <LikeIcon>
            {likeStatus ? <AiFillHeart /> : <AiOutlineHeart />}
            {doExplosion ? <ConfettiInstance /> : null}
          </LikeIcon>
         
          <LikeCount>{likeCount}</LikeCount>
        </ActionButton>

        {item.authorUserID === loggedInUserID ? (
          <ActionButton onClick={showOptions}>
            <BsThreeDots />
          </ActionButton>
        ) : null}
      </ActionButtons>
    </Container>
  );

  function showOptions() {
    setForm({
      options: [
        { name: "Delete It", onClick: archivePost },
        { name: "Edit It", onClick: editPost },
      ],
    });

    async function editPost() {
      setForm({
        title: "Edit Post",
        component: <AccomplishmentAdder dateString={item.date} />,
      });
    }

    async function archivePost() {
      setForm({ loading: true });
      await serverLine.delete("/content/?postID=" + item._id);
      window.location.reload();
    }
  }

  function likePost() {
    if (!loggedInUserID) return promptLogin();
    setLikeStatus(!likeStatus);
    if (likeStatus) {
      setLikeCount(likeCount - 1);
      setDoExplosion(false);
    } else {
      setLikeCount(likeCount + 1);
      setDoExplosion(true);
    }
    serverLine.post("/like", { postID: item._id });
  }
}
