import { styled } from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;
`;
const Name = styled.div`
  font-size: 18px;
  font-weight: 800;
`;
const Btn = styled.div`
  padding: 15px 25px;
  cursor: pointer;

  ${({ highlight }) => {
    if (highlight)
      return `
        background:var(--color);
        color:var(--bgColor);
    `;

    return `
    &:hover {
      background: var(--translucentHard);
    }
    
    `;
  }}
`;
const Toggle = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  background: var(--translucent);
  overflow: hidden;
`;

export default function CustomToggle({ name, value, onChange, options }) {
  // return JSON.stringify(value);

  if (!options)
    options = [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ];

  return (
    <Container>
      <Name>{name}</Name>
      <Toggle>
        {options.map((item) => (
          <Btn
            onClick={() => {
              onChange(item.value);
            }}
            highlight={item.value === value}
          >
            {item.label}
          </Btn>
        ))}
      </Toggle>
    </Container>
  );
}
