export default function getFollowStatus(loggedInUser, userID) {
  let initialStatus = "NEGATIVE";
  if (!loggedInUser) return initialStatus;

  if (loggedInUser.followingUsers) {
    if (loggedInUser.followingUsers.includes(userID)) return "POSITIVE";
  }

  if (loggedInUser.followReqSent) {
    if (loggedInUser.followReqSent.includes(userID)) return "PENDING";
  }

  return initialStatus;
}
