import styled from "styled-components";
import Link from "./Link";

const Container = styled.div`
  width: calc(100vw - 40px);
  height: auto;
  border-radius: 15px;
  overflow: hidden;
  cursor: pointer;
  background-color: var(--translucent);
  display: flex;
  flex-direction: row;
  gap: 15px;
  position: relative;
  padding: 20px;

  @media (min-width: 800px) {
    width: auto;
  }

  &:hover {
    background-color: var(--translucentHard);
  }
`;

const Images = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;

  @media (min-width: 800px) {
    width: auto;
  }
`;

const Rank = styled.div`
  font-size: 10px;
`;
const TheImage = styled.img`
  height: calc((100vw - 40px) / 4);
  width: calc((100vw - 40px) / 4);
  filter: grayscale(1) blur(1px);
  object-fit: cover;
  transition: 0.25s ease-in-out;
  z-index: 55;
  @media (min-width: 800px) {
    height: calc((80vw - 20vw - 30px) / 4);
    width: calc((80vw - 20vw - 30px) / 4);
  }

  &:hover {
    z-index: 100;
    transform: scale(2);
  }
`;

const First = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  margin: 0;
  /* font-size: 15px; */
`;

const SecondLine = styled.span`
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin: 0;
  font-size: 15px;
  opacity: 0.8;
  @media (min-width: 800px) {
    width: auto;
  }
`;
const Name = styled.h3`
  padding: 0;
  margin: 0;
`;
const Username = styled.div``;
const Small = styled.div``;

export default function UserBoxSmall({ item }) {
  return (
    <Link href={"/profile/" + item.username}>
      <Container>
        <Name>{item.name}</Name> <Username>@{item.username}</Username>
      </Container>
    </Link>
  );
}
