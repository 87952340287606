import WithHeader from "./WithHeader";
import { FiSearch } from "react-icons/fi";
import { serverLine } from "../controllers/serverLine";
import LoadingSection from "./LoadingSection";

import styled from "styled-components";
import { useContext, useState } from "react";
import { useEffect } from "react";
import Link from "./Link";
import FollowButton from "./FollowButton";
import Context from "../Context";
import CurrentPackage from "./CurrentPackage";
import getCurrentPackage from "../controllers/getCurrentPackage";
import virtualProducts from "../data/virtualProducts";
import productionProducts from "../data/productionProducts";
import upgradeExtension from "../controllers/upgradeExtension";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 25px;
`;


const Title = styled.div`
  // text-align:center;
  width:100%;
  opacity:0.5;
`


const ProductCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding:25px;
  border-radius:15px;
  border: 1px solid var(--translucentHard);
  gap:15px;
`
const ProductTop = styled.div`
  display:flex;
  flex-direction:row;
  gap:15px;
  font-size:25px;
  align-items:center;

`
const ProductTitle = styled.div`
  font-weight: 900;

`
const ProductSubTitle = styled.div`
  font-weight:  300;
  opacity:  0.5;
`
const Description = styled.div`
  display:flex;
  flex-direction:column;
  gap:5px;
  margin: 15px 0;
`


const UpgradeButton = styled.div`
  width:150px;
  background:var(--color);
  color: var(--bgColor);
  padding:15px;
  display:flex;
  justify-content:center;
  align-items:center;
  border-radius:15px;
  cursor:pointer;
  transition:0.15s ease-in;

  &:hover{
    transform: scale(0.9);
    
  }
`
const Products = styled.div`

  display:flex;
  flex-direction:column;
  gap:25px;

`

export default function PurchaseNewPlan() {

  const { loggedInUserID , loggedInUser, setForm} = useContext(Context);
  const [loading, setLoading] = useState(false)

  if(!loggedInUserID) return "Login Required!"
  if(loading) return <LoadingSection/>

  

  let productList = virtualProducts
  
  if(process.env.NODE_ENV == "production" || process.env.REACT_APP_ENV_TYPE == "production" ){
    productList = productionProducts
    console.log("Is production")
  }

 
  

  return <Container> 

  <Title>Upgrade To Premium</Title>

  <Products>

   

    <ProductCard>
      <ProductTop>
        <ProductTitle> Silver </ProductTitle>
        <ProductSubTitle> 5$ / Year</ProductSubTitle>
        </ProductTop>
        <ProductSubTitle> Ideal For Individual</ProductSubTitle>
        <Description>
            <DescriptionLine> See stats, older than two month </DescriptionLine>
            <DescriptionLine> Compare upto 10 people at once </DescriptionLine>
            <DescriptionLine>Compare upto 10 projects at once</DescriptionLine>
            <DescriptionLine>Compare upto 10 languages at once</DescriptionLine>
        </Description>
        <UpgradeButton onClick={ fetchLink(productList[0].id )}>Pay 5$</UpgradeButton>
    </ProductCard>

    <ProductCard>
    <ProductTop>
        <ProductTitle> Gold </ProductTitle>
        <ProductSubTitle> 49$ / Year</ProductSubTitle>
        </ProductTop>
        <ProductSubTitle>Ideal For Company</ProductSubTitle>
        <Description>
            <DescriptionLine> See stats older than 1 month </DescriptionLine>
            <DescriptionLine> Compare unlimited people at once </DescriptionLine>
            <DescriptionLine>Compare unlimited projects at once</DescriptionLine>
            <DescriptionLine>Compare unlimited languages at once</DescriptionLine>
        </Description>

        <UpgradeButton onClick={fetchLink(productList[1].id )}>Pay 49$</UpgradeButton>
      
    </ProductCard>


    </Products>
  </Container>


 function fetchLink(productID){

  return async function(){

    let extLogin = localStorage.getItem("extension-login")

    let currentPackage = getCurrentPackage(loggedInUser)

    if(currentPackage){
      setLoading(true)
      await serverLine.post("/terminate-membership")
      setLoading(false)
    }

    if(extLogin){
      return upgradeExtension(setForm)
    }
    

    setLoading(true);

    let link = await serverLine.get("/stripe-checkout-session/?productID="+productID)

    window.location = link
    setLoading(false)
  }

  }
}


const DescriptionLineContainer = styled.div`
display:flex;
flex-direction:row;
gap:5px;
align-items:center;
`

const Point = styled.div`
  font-size:35px;
  margin-top:-5px;
`

const DescriptionText = styled.div`
  
`



function DescriptionLine({children}){
  return <DescriptionLineContainer>
    <Point>·</Point>
    <DescriptionText>{children}</DescriptionText>
  </DescriptionLineContainer>
}