export default function Link({ href, children }) {
  return <div onClick={goTo}>{children}</div>;

  function goTo() {
    document.body.scrollTop = 0
    window.scrollTo(0,0); 

    if(href.indexOf("http") !== -1){
      return window.open(href)
    }
    window.navigate(href);
  }
}
