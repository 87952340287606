import WithHeader from "./WithHeader";
import { FiSearch } from "react-icons/fi";
import { serverLine } from "../controllers/serverLine";
import LoadingSection from "./LoadingSection";

import styled from "styled-components";
import { useContext, useState } from "react";
import { useEffect } from "react";
import Link from "./Link";
import FollowButton from "./FollowButton";
import Context from "../Context";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 25px;
`;

const Notification = styled.div`
  background: var(--translucent);
  padding: 25px 25px;
  /* cursor: pointer; */
  width: 100%;
  border-radius: 15px;

  /* text-transform: capitalize; */
  background: var(--translucent);
  color: var(--color);
  display: flex;
  flex-direction: column;
  gap: 25px;

  ${({ seen }) => {
    if (!seen)
      return `
      background:var(--color);
      color:var(--bgColor);`;
  }}
`;

const NotificationText = styled.div`
  text-transform: capitalize;
`;
const Button = styled.button`
  /* flex: 1; */
  position: relative;
  background-color: var(--translucent);
  border: 1px solid;
  font-size: 15px;
  padding: 10px 25px;
  color: var(--color);
  cursor: pointer;
  border-radius: 50px;

  &:hover {
    background-color: var(--color);
    color: var(--bgColor);
  }

  ${({ seen }) => {
    if (!seen)
      return `
      background:var(--color);
      color:var(--bgColor);
      

      &:hover {
        color: var(--color);
        background-color: var(--bgColor);
      }
      
      `;
  }}
`;
const NotificationButtons = styled.div`
  display: flex;
  flex-direction: row;

  gap: 15px;
`;

export default function Notifications() {
  const { loggedInUserID,loggedInUser } = useContext(Context);
  const [res, setRes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [latestLoggedInUser, setLatestLoggedInUser] = useState(null);

  useEffect(() => {
    if (loggedInUserID) {
      serverLine.get("/notifications").then((data) => {
        if (window.setNotificationCount) {
          window.setNotificationCount(0);
        }

        var parent = window.parent;

        if (parent) {
          parent.postMessage("CLEAR_NOTIFICATION", "*");
        }

        setRes(data);
        setLoading(false);
      });

      serverLine.get("/logged-in-user").then((data) => {
        setLatestLoggedInUser(data);
      });
    }
  }, [loggedInUserID]);

  if (!loggedInUserID) return "Login to continue";

  if(!loggedInUser) return <LoadingSection/>

  if (loading) return <LoadingSection />;
  if (!latestLoggedInUser) return <LoadingSection />;

  return <Container>{renderResult()}</Container>;

  function renderResult() {
    if (!res) return [];
    if (!res.notifs) return [];
    return res.notifs.map((item) => {
      return getNotif({ item });
    });
  }

  function getNotif({ item }) {
    let seen = hasSeen(item.createdAt);

    // return JSON.stringify(item);
    if (!item.sender)
      return (
        <Notification>The Person involved has deleted the account</Notification>
      );

    if (item.type == "FOLLOW") {
      if (item.status == "DECLINED")
        return (
          <Notification style={{ cursor: "unset" }} seen={seen} key={item.id}>
            <NotificationText>
              You declined follow request of {item.sender.username}
            </NotificationText>
          </Notification>
        );

      if (item.status == "PENDING")
        return (
          <Notification style={{ cursor: "unset" }} seen={seen} key={item.id}>
            <NotificationText>
              <Link href={"/" + item.sender.username}>
                {item.sender.username}
              </Link>{" "}
              wants to follow you
            </NotificationText>
            <NotificationButtons>
              <Button seen={seen} onClick={followApprove(true, item._id)}>
                Approve
              </Button>
              <Button seen={seen} onClick={followApprove(false, item._id)}>
                Decline
              </Button>
            </NotificationButtons>
          </Notification>
        );

      return (
        <Notification seen={seen} key={item.id}>
          <NotificationText>
            {item.sender.username} started following you
          </NotificationText>
          <NotificationButtons>
            <Button
              seen={seen}
              onClick={goTo("/" + item.sender.username)}
            >
              Visit Profile
            </Button>
            <FollowButton
              customProps={{ seen: seen }}
              CustomContainer={Button}
              latestLoggedInUser={latestLoggedInUser}
              user={item.sender}
            />
          </NotificationButtons>
        </Notification>
      );
    } else if (item.type === "HIRE_PROPOSAL") {
      return (
        <Notification seen={seen} key={item.id}>
          <NotificationText>
            {item.sender.username} sent you a note.
          </NotificationText>
          <NotificationText>{item.data.message}</NotificationText>
          <NotificationButtons>
            <Button
              seen={seen}
              onClick={goTo("/" + item.sender.username)}
            >
              Open Their Profile
            </Button>
          </NotificationButtons>
        </Notification>
      );
    } else if (item.type === "LIKE") {
      return (
        <Notification seen={seen} key={item.id}>
          <NotificationText>
            {item.sender.username} has liked your post
          </NotificationText>
          <NotificationButtons>
            <Button
              seen={seen}
              onClick={goTo("/" + item.sender.username)}
            >
              Open Their Profile
            </Button>
            <Button seen={seen} onClick={goTo("/post/" + item.postID)}>
              Open The Post
            </Button>
          </NotificationButtons>
        </Notification>
      );
    } else if (item.type === "FOLLOW_DECLINED") {
      return (
        <Notification seen={seen} key={item.id}>
          <NotificationText>
            {item.sender.username} has declined your follow request
          </NotificationText>
          <NotificationButtons>
            <Button
              seen={seen}
              onClick={goTo("/" + item.sender.username)}
            >
              Open Their Profile
            </Button>
          </NotificationButtons>
        </Notification>
      );
    } else if (item.type === "FOLLOW_APPROVED") {
      return (
        <Notification seen={seen} key={item.id}>
          <NotificationText>
            {item.sender.username} has approved your follow request
          </NotificationText>
          <NotificationButtons>
            <Button
              seen={seen}
              onClick={goTo("/" + item.sender.username)}
            >
              Open Their Profile
            </Button>
          </NotificationButtons>
        </Notification>
      );
    } else {
      return (
        <Notification seen={seen} key={item.id}>
          <NotificationText>
            {item.sender.username} - {item.type}
          </NotificationText>

          <NotificationButtons>
            <Button seen={seen} onClick={followApprove(true, item._id)}>
              Open Profile
            </Button>
          </NotificationButtons>
        </Notification>
      );
    }
  }

  function followApprove(status, notificationID) {
    return async () => {
      if (status) {
        updateNotif(notificationID, "status", "POSITIVE");
      } else {
        updateNotif(notificationID, "status", "DECLINED");
      }

      await serverLine.post("/approve-follow", { notificationID, status });
    };
  }

  function updateNotif(id, field, value) {
    let newNotifs = { ...res };
    let content = [...newNotifs.notifs];

    for (let item of content) {
      if (item._id == id) {
        item[field] = value;
      }
    }

    setRes(newNotifs);
  }

  function hasSeen(createdAt) {
    let createdAtEpochs = new Date(createdAt).valueOf();
    let notificationsSeenAt = new Date(
      res.notificationsSeenAt ? res.notificationsSeenAt : 0
    ).valueOf();
    // console.log(createdAtEpochs, notificationsSeenAt);

    return createdAtEpochs < notificationsSeenAt;
  }

  function goTo(href) {
    return () => {
      window.navigate(href);
    };
  }
}
