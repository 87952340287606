import styled from "styled-components";

import PostBox from "./PostBox";

// import ImageCapture from "image-capture";

const Main = styled.div`
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  gap: 20px;
`;

const Container = styled.div`
  width: 100%;

  @media (min-width: 800px) {
    margin: 0;
    /* width: auto; */

    display: flex;
    /* flex: 1; */
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Tags = styled.div`
  display: grid;
  gap: 2px;
  background-color: var(--translucent);
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 50px;
  grid-template-columns: 1fr 1fr;
`;

const Title = styled.h2``;

export default function PostsSection({ posts }) {
  return (
    <Container>
      {/* <Title>Content</Title> */}
      <Main>{doRender()}</Main>
    </Container>
  );

  function doRender() {
    let content = [];

    posts.map((item) => {
      content.push(<PostBox key={item._id} item={item} />);
    });

    return content;
  }
}
