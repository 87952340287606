import styled from "styled-components";

import Header from "./Header";
import { useContext } from "react";
import Context from "../Context";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  overflow: hidden;
 
  width: 100vw;
  padding-bottom: 50px;


  /* ${({ isInsideExtension }) => {
    if (isInsideExtension)
      return `
    transform-origin: 50% 100%;
    transform:scale(0.9);
    `;
  }} */
`;

const Main = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;

  gap: 50px;
  width: 38vw;
  justify-content: flex-start;

  align-items: center;

  @media (max-width: 1200px) {
    width: 62vw;
  }

  @media (max-width: 700px) {
    width: ${() =>
      localStorage.getItem("extension-login") ? "100vw" : "90vw"};
  }

  ${({ isInsideExtension }) => {
    if (isInsideExtension) return `zoom:90%;`;
  }}
`;

export default function WithHeader({ children }) {
  const { loggedInUserID } = useContext(Context);

  let disablePadding = false;

  let path = window.location.pathname;
  if (!loggedInUserID) {
    if (path == "/") return children;
    if (path == "/auth-redirect") return children;
  }

  return (
    <Container>
      <Header />
      <Main
        isInsideExtension={localStorage.getItem("extension-login")}
        disablePadding={disablePadding}
      >
        {children}
      </Main>
    </Container>
  );
}
