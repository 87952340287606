import logout from "./logout";

class ServerLine {
  get(route) {
    return executer(route);
  }

  post(route, body) {
    return executer(route, body, "POST");
  }

  delete(route) {
    return executer(route, null, "DELETE");
  }

  patch(route, body) {
    return executer(route, body, "PATCH");
  }
}

async function executer(route, body, method) {
  let requestType = "GET";
  if (body) requestType = "POST";
  if (method) requestType = method;

  let token = localStorage.getItem("paratime-user-token");

  let headerParam = {
    "Content-Type": "application/json",
  };

  let authorization = { authToken: token };

  if (token) {
    headerParam.authorization = JSON.stringify(authorization);
  }

  let requestObject = {
    // mode: "cors",
    method: requestType,
    headers: headerParam,
    // credentials: "include",
  };

  if (body) requestObject.body = JSON.stringify(body);

  let base = process.env.REACT_APP_SERVER;

  if (!base) base = "https://backend.paratime.app";

  if (process.env.REACT_APP_ENV_TYPE === "development")
    base = `http://localhost:8080`;

  route = base + "/api/v1/paratime" + route;
  console.log(route);

  let res = await fetch(route, requestObject);

  let jsonData = await res.json();

  if (jsonData.error) {
    if (jsonData.error === "Invalid user") {
      logout();
    } else {
      throw Error(jsonData.error);
    }
  }
  return jsonData.data;
}

const serverLine = new ServerLine();

export { serverLine };
