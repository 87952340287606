import styled from "styled-components";

import logout from "../controllers/logout";
import { useAlert } from "react-alert";

import { useContext } from "react";
import Context from "../Context";
import { useNavigate } from "react-router-dom";
import openLink from "../controllers/openLink";

const Box = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 25px;
`;

const Button = styled.div`
  cursor: pointer;
  /* flex: 1; */
  padding: 15px 15px;
  background: var(--translucent);
  border-radius: 15px;
  width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background: var(--color);
    color: var(--bgColor);
  }
`;

export default function Settings() {
  const { loggedInUserID } = useContext(Context);

  const navigate = useNavigate();

  if (!loggedInUserID) return "Login to continue";

  return (
    <Box>

      <Button
        onClick={() => {
          navigate("/upgrade");
        }}
      >
        Premium
      </Button>

      {loggedInUserID ? <Button onClick={logout}>Logout</Button> : null}

      <Button
        onClick={() => {
          navigate("/edit-profile");
        }}
      >
        Edit Profile
      </Button>



      <Button onClick={openLink("https://arnav.upon.one")}>
        About Developer
      </Button>
      <Button onClick={openLink("https://upon.one")}>About Company</Button>
    </Box>
  );
}
