import Link from "../Link";
import styled, { keyframes } from "styled-components";

import getStatColor from "../../controllers/getStatColor";
import getMaxTime from "../../controllers/getMaxTime";
import toMinsOrHours from "../../controllers/toMinOrHours";
import getDateString from "../../controllers/getDateString";
import { AiOutlineEdit } from "react-icons/ai";
import shadeColor from "../../controllers/shadeColor";
import Select from "react-select";
import { useContext, useState } from "react";
import CustomSelect from "../CustomSelect";
import GraphDurationSelect from "./GraphDurationSelect";
import getDateRange from "../../controllers/statLibrary/getDateRange";
import GraphDurationPaginator from "./GraphDurationPaginator";
import { useNavigate } from "react-router-dom";
import getFreeTierMaxDuration from "../../controllers/getFreeTierMaxDuration";
import GetPremium from "../GetPremium";
import getCurrentPackage from "../../controllers/getCurrentPackage";
import Context from "../../Context";
import GetPremiumToSeeMoreItems from "../GetPremiumToSeeMoreItems";

const Padding = styled.div`
  width: 100%;
`;

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-item: center;
  margin-bottom: 25px;

  @media (max-width: 900px) {
    /* display: none; */
    flex-direction: column;
    gap: 25px;
    align-items: flex-start;
  }
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 300;
  display: flex;
  justify-content: center;
  align-items: center;
  /* opacity: 0.8; */
  text-transform: capitalize;

  @media (max-width: 900px) {
    /* display: none; */
  }
`;

const Columns = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px 25px;
  background-color: var(--translucent);
  border-radius: 15px;
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  /* cursor: pointer; */
  align-items: center;
  gap: 15px;
`;

const scaleBar = (width) => keyframes`
    0% {
      width : 0; 
    }
    100% {
        width : ${width};
    }
`;

const ColorfulBar = styled.div`
  height: 30px;
  background: steelblue;
  border-radius: 5px 25px 25px 5px;
  animation: ${(props) => scaleBar(props.width)} 0.25s ease-out;
`;

const Label = styled.div`
  opacity: 0.5;
  text-transform: capitalize;
  font-size: 16px;
  ${({ hasLink }) => {
    if (hasLink)
      return `
      cursor:pointer;
      &:hover{
        text-decoration: underline;
      }
    `;
  }}
`;

const Actions = styled.div`
  display: flex;
  gap: 15px;
`;

const Message = styled.div``;

const SeeMoreButton = styled.div`
  background-color: var(--translucent);
  padding: 10px 15px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 15px;
  text-decoration: underline;
  border-radius: 10px;
`;

const selectOptions = [
  { value: "DATE", label: "Date" },
  { value: "WEEK", label: "Week" },
  { value: "MONTH", label: "Month" },
  { value: "YEAR", label: "Year" },
  { value: "EVER", label: "All Time" },
];

let oneMonthOld = getFreeTierMaxDuration();

let maxItemsPerState = {
  FREE: 5,
  SILVER: 10,
  GOLD: 1000,
};

export default function VerticalBarGraph({
  dataProcessor = () => {},
  title = "Demo Stat",
  rawData,
  disableFiltering,
  disableType,
  disableSorting,
}) {
  const { loggedInUser, setForm } = useContext(Context);
  const [timeSpan, setTimeSpan] = useState({ value: "DATE", label: "Date" });
  const [pagination, setPagination] = useState(0);
  const [seeMoreStatus, setSeeMoreStatus] = useState("FREE");
  let maxItemsBeforeSeeMore = maxItemsPerState[seeMoreStatus];

  const navigate = useNavigate();

  if (!rawData) return <Message>No data to show</Message>;

  let dateRange = getDateRange({
    timeSpanType: timeSpan.value,
    pagination,
    graphType: "VERTICAL",
  });

  let content = null;
  let dataArray = [];

  let currentPackage = getCurrentPackage(loggedInUser);

  if (dateRange.startDate.getTime() < oneMonthOld && !currentPackage) {
    content = <GetPremium />;
  } else {
    let dataObj = dataProcessor({ ...dateRange, rawData });

    for (let projectID in dataObj) {
      dataArray.push({
        label: dataObj[projectID].label,
        value: dataObj[projectID].value,
        onClick: dataObj[projectID].onClick,
      });
    }

    if (!disableSorting) {
      dataArray = dataArray.sort(function (a, b) {
        return b.value - a.value;
      });
    }

    let enableSeeMore = dataArray.length >= maxItemsBeforeSeeMore;

    let slicedDataArray = [...dataArray];

    slicedDataArray = slicedDataArray.slice(0, maxItemsBeforeSeeMore);

    let seeMoreBtn = <SeeMoreButton onClick={seeMore}>See More</SeeMoreButton>;

    if (!enableSeeMore) seeMoreBtn = null;
    if (slicedDataArray >= dataArray.length) seeMoreBtn = null;

    content = (
      <Columns>
        {getStatBoxes()} {seeMoreBtn}{" "}
      </Columns>
    );
  }

  return (
    <Padding>
      <TitleRow>
        <Title>{title}</Title>
        {getActions()}
      </TitleRow>
      {content}
    </Padding>
  );

  function getActions() {
    if (disableFiltering) return null;
    let actionComp = (
      <>
        <Actions>
          {timeSpan.value === "EVER" ? null : (
            <GraphDurationPaginator
              rangeLabel={dateRange.rangeLabel}
              setValue={setPagination}
              value={pagination}
            />
          )}

          {disableType ? null : (
            <GraphDurationSelect
              value={timeSpan}
              options={selectOptions}
              setValue={(val) => {
                setTimeSpan(val);
                setPagination(0);
              }}
            />
          )}
        </Actions>
      </>
    );

    return actionComp;
  }

  function seeMore() {
    let currentPackage = getCurrentPackage(loggedInUser);

    if (currentPackage) {
      let product = currentPackage[0];
      let productName = product.name;

      if (seeMoreStatus == "FREE") {
        setSeeMoreStatus("SILVER");
      } else if (seeMoreStatus === "SILVER") {
        if (productName == "Gold") {
          setSeeMoreStatus("GOLD");
        } else {
          setForm({ component: <GetPremiumToSeeMoreItems toGold={true} /> });
        }
      }
    } else {
      setForm({ component: <GetPremiumToSeeMoreItems /> });
    }
  }

  function getStatBoxes() {
    let theList = [];

    if (!dataArray) return <Message>No data to show</Message>;
    if (!dataArray.length) return <Message>No data to show</Message>;

    let maxVal = getMaxValue();

    let slicedDataArray = [...dataArray];

    slicedDataArray = slicedDataArray.slice(0, maxItemsBeforeSeeMore);

    for (let item of slicedDataArray) {
      let width = item.value / maxVal;
      width = width * 100;

      width = width * 0.4;

      let bgColor = "#13512D";

      bgColor = shadeColor(bgColor, (item.value / maxVal) * 100);

      theList.push(
        <Item>
          <ColorfulBar
            width={width + "%"}
            style={{ width: width + "%", backgroundColor: bgColor }}
          />
          <Label
            hasLink={item.onClick}
            onClick={() => {
              if (item.onClick) item.onClick();
            }}
          >
            {item.label}
          </Label>
        </Item>
      );
    }

    return theList;
  }

  function getMaxValue() {
    let maxVal = 0;

    for (let item of dataArray) {
      if (item.value > maxVal) maxVal = item.value;
    }

    return maxVal;
  }
}
