import styled from "styled-components";

const Path = styled.path`
  stroke: var(--color);
`;

const Path2 = styled.path`
  stroke: var(--color);
  fill: var(--color);
  stroke-width: 1;
`;

const SVG = styled.svg`
  height: 35px;
  width: 35px;
`;

export default function LogoSVG() {
  return (
    <SVG
      width="53"
      height="49"
      viewBox="0 0 53 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        d="M26.7477 25.3476L39.6167 39.8359C40.1896 40.4809 39.7317 41.5 38.869 41.5H13.131C12.2683 41.5 11.8104 40.4809 12.3833 39.8359L25.2523 25.3476C25.6502 24.8997 26.3498 24.8997 26.7477 25.3476Z"
        strokeWidth="3.5"
      />
      <Path
        d="M25.2523 23.6524L12.3833 9.16409C11.8104 8.51911 12.2683 7.5 13.131 7.5L38.869 7.5C39.7317 7.5 40.1896 8.51911 39.6167 9.16409L26.7477 23.6524C26.3498 24.1003 25.6502 24.1003 25.2523 23.6524Z"
        strokeWidth="3.5"
      />
      {/* <Path2
        d="M32.4203 15.5L26 23.5875L19.5797 15.5L32.4203 15.5Z"
        // strokeWidth="10"
      />
      <Path2 d="M25.9416 36.5857L15.0273 39.5171C14.8293 39.5703 14.7575 39.6552 14.7218 39.7143C14.6741 39.7932 14.6417 39.9175 14.6613 40.0654C14.6808 40.2134 14.7443 40.3249 14.8108 40.3888C14.8606 40.4366 14.952 40.5 15.157 40.5H37.3517C37.5582 40.5 37.6495 40.4361 37.6988 40.3885C37.7652 40.3244 37.8287 40.2123 37.8477 40.0634C37.8667 39.9145 37.8335 39.79 37.7854 39.7113C37.7496 39.6528 37.6773 39.568 37.4775 39.5161L26.197 36.5847C26.1132 36.5629 26.0252 36.5633 25.9416 36.5857Z" />
     */}
    </SVG>
  );
}
