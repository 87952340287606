import { styled } from "styled-components"
import Link from "./Link"
import getUpgradeLink from "../controllers/getUpgradeLink"
import { Navigate, useNavigate } from "react-router-dom"
import sendMessageOutsideIframe from "../controllers/sendMessageOutsideIframe"

const Container = styled.div`
    display:flex;
    flex-direction:column;
    gap:0;
    align-items:center;
    background:var(--translucent);
    border-radius:15px;
    padding:50px;
`

const Title = styled.h3``

const Button = styled.div`
cursor: pointer;
/* flex: 1; */
padding: 15px 25px;
background: var(--translucent);
border-radius: 15px;

display: flex;
justify-content: center;
align-items: center;
&:hover {
  background: var(--color);
  color: var(--bgColor);
}
`

export default function GetPremium(){

    const navigate = useNavigate()

    return <Container>
        <Title>Get premium to see stat older than 2 months</Title>
        <Link href="/upgrade">
        <Button onClick={getPremium}>Get Premium</Button>
        </Link>
    </Container>

    function getPremium(){
        let extLogin = localStorage.getItem("extension-login")

        if(extLogin){
            sendMessageOutsideIframe('OPEN_LINK',getUpgradeLink());
        }else{
            navigate("/upgrade")
        }
    }
}