import { useContext, useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import Context from "../Context";
import { serverLine } from "../controllers/serverLine";
import LoadingSection from "./LoadingSection";
import onChangeStop from "../controllers/onChangeStop";
import { BsSend } from "react-icons/bs";
import ProfilePicture from "./ProfilePicture";

const Container = styled.div`
  padding: 25px;
  display: flex;
  gap: 30px;
  flex-direction: column;
  align-item: flex-start;
`;
const UserBox = styled.div`
  display: flex;
  flex-direction: row;
  /* width: 62%; */
  overflow: hidden;
  gap: 15px;
  /* background: var(--translucent); */
  border-radius: 50px;
  align-items: center;
  font-size: 15px;
`;
const UserData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
const Name = styled.div`
  font-weight: 900;
`;
const Username = styled.div`
  opacity: 0.7;
`;
const MessageBox = styled.textarea`
  border: none;
  outline: none;
  resize: none;
  width: 100%;
  font-family: 'Inter';
  background: var(--translucent);
  /* font-weight: 600; */
  /* border-bottom: 2px solid var(--color); */
  padding: 15px;
  border-radius: 15px;
  font-size: 18px;
  color: var(--color);

  /* background: transparent; */
`;
const PostButton = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  padding: 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  font-size: 15px;
  background: var(--translucent);
  cursor: pointer;
  width: 100px;

  &:hover {
    background: var(--translucentHard);
    color: var(--color);
  }
`;
const PostButtonIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const PostButtonText = styled.div``;

const Label = styled.div`
  opacity: 0.5;
  margin-bottom: -15px;
`;

export default function HireProposalSender({ user }) {
  const { loggedInUser, setLoggedInUser, setForm, showAlert } =
    useContext(Context);
  const [loading, setLoading] = useState(false);
  const [theMessage, setTheMessage] = useState(null)

  const messageRef = useRef(null);

  useEffect(()=>{

    if(loggedInUser && theMessage == null){
      let message = "";
      if (loggedInUser.hireProposalMessage == null) {
        message = `Please! Send your resume on ${loggedInUser.email}`;
      } else {
        message = loggedInUser.hireProposalMessage;
      }

      setTheMessage(message)
    }

  },[loggedInUser])


  const resizeTextArea = () => {
    if (messageRef.current) {
      messageRef.current.style.height = "auto";
      messageRef.current.style.height = messageRef.current.scrollHeight + "px";
    }
  };

  useEffect(resizeTextArea, [theMessage]);

  if(theMessage == null) return 

  if (loading) return <LoadingSection />;

  return (
    <Container>
      <UserBox>
        <ProfilePicture size={"60px"} user={user} />
        <UserData>
          <Name>{user.name}</Name>
          <Username>{user.username}</Username>
        </UserData>
      </UserBox>

      <Label>Message</Label>
      <MessageBox ref={messageRef} value={theMessage} onChange={updateMessage} />

      <PostButton onClick={post}>
        <PostButtonIcon>
          <BsSend />
        </PostButtonIcon>
        <PostButtonText>Send</PostButtonText>
      </PostButton>
    </Container>
  );

  async function post() {
    if (!theMessage) return showAlert("Message is required");
    setLoading(true);

    try {
      await serverLine.post("/hire-proposal", {
        receiverUserID: user._id,
        message: theMessage,
      });
      setForm(null);
      setLoading(false);
      showAlert("Note Sent");
    } catch (e) {
      setLoading(false);
      showAlert(e.message);
    }
  }

  function updateMessage({ target }) {
    let newLoggedInUser = { ...loggedInUser };
    newLoggedInUser.hireProposalMessage = target.value;
    setTheMessage(target.value)
    setLoggedInUser(newLoggedInUser);

    onChangeStop({
      callback: () => {
        serverLine.patch("/profile", {
          changes: { hireProposalMessage: target.value },
        });
      },
      name: "hireProposalMessage",
    });
  }
}
