
import styled from "styled-components";

import ConfettiExplosion from "react-confetti-explosion";



const ConfettiPos = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

let confettiOptions = {
  force: 0.5,
  duration: 2200,
  particleCount: 15,
  width: 600,
};

export default function ConfettiInstance() {
  

       return <ConfettiPos>
          <ConfettiExplosion {...confettiOptions} />
        </ConfettiPos>
}