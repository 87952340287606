import { useContext } from "react";
import styled from "styled-components";
import Context from "../Context";
import { useEffect, useState } from "react";
import LoadingSection from "./LoadingSection";
import { serverLine } from "../controllers/serverLine";
import LogoSVG from "./LogoSVG";
import BrandContainer from "./BrandContainer";

const Main = styled.div`
  display: flex;
  gap: 100px;
  flex-direction: column;
  padding: 50px 0;
  width: 100vw;
  /* justify-content: space-between; */
  height: 100vh;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  gap: 25px;
  flex-direction: column;
  width: 38vw;
  align-items: center;
  color: var(--color);

  @media (max-width: 1200px) {
    width: 62vw;
  }

  @media (max-width: 700px) {
    width: ${() =>
      localStorage.getItem("extension-login") ? "100vw" : "90vw"};
  }
`;

const Tags = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
const Input = styled.input`
  background-color: var(--translucent);
  border: none;
  padding: 15px 25px;
  color: var(--color);
  border-radius: 25px;
`;

const Button = styled.button`
  padding: 15px 45px;
  color: var(--color);
  cursor: pointer;
  border-radius: 25px;
  font-size: 19px;
  border: none;
  background-color: var(--translucent);

  &:hover {
    background: var(--color);
    color: var(--bgColor);
  }
`;

const H1 = styled.h1`
  margin-top: 100px;
  text-align: center;
  font-size: 18px;
  margin-bottom: 0;
`;

const Small = styled.small`
  margin-bottom: 50px;
  text-align: center;
  font-size: 15px;
  opacity: 0.7;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export default function SelectAccountPrivacy() {
  const [loading, setLoading] = useState(false);

  if (loading) return <LoadingSection />;

  return (
    <Main>
      <BrandContainer />

      <Container>
        <H1>Select Account Privacy</H1>
        <Small>
          In case of private account, only approved followers can see your stat
          and accomplishments.
        </Small>

        <Buttons>
          <Button
            onClick={() => {
              save("PRIVATE");
            }}
          >
            Private
          </Button>

          <Button
            onClick={() => {
              save("PUBLIC");
            }}
          >
            Public
          </Button>
        </Buttons>
      </Container>
    </Main>
  );

  async function save(selectedPrivacy) {
    setLoading(true);
    await serverLine.patch("/profile", {
      changes: { privacy: selectedPrivacy },
    });
    window.location.reload();
  }
}
