import WithHeader from "./WithHeader";
import { FiSearch } from "react-icons/fi";
import { serverLine } from "../controllers/serverLine";
import LoadingSection from "./LoadingSection";

import styled from "styled-components";
import { useContext, useState } from "react";
import { useEffect } from "react";
import Link from "./Link";
import FollowButton from "./FollowButton";
import Context from "../Context";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 25px;
`;


const Title = styled.div`
  // text-align:center;
  width:100%;
  opacity:0.5;
`


const ProductCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding:25px;
  border-radius:15px;
  border: 1px solid var(--translucentHard);
  gap:15px;
`
const ProductTop = styled.div`
  display:flex;
  flex-direction:row;
  gap:15px;
  font-size:25px;
  align-items:center;

`
const ProductTitle = styled.div`
  font-weight: 900;

`
const ProductSubTitle = styled.div`
  font-weight:  300;
  opacity:  0.5;
`
const Description = styled.div`
  display:flex;
  flex-direction:column;
  gap:5px;
  margin: 15px 0;
`


const UpgradeButton = styled.div`
  width:150px;
  background:var(--color);
  color: var(--bgColor);
  padding:15px;
  display:flex;
  justify-content:center;
  align-items:center;
  border-radius:15px;
  cursor:pointer;
  transition:0.15s ease-in;

  &:hover{
    transform: scale(0.9);
    
  }
`

export default function StripePaymentSuccess() {

  const { loggedInUserID } = useContext(Context);

  const [status, setStatus] = useState(null)

  useEffect(()=>{

    serverLine.get("/check-stripe-payment-success").then((newData)=>{
     

      if(newData.status == "complete"){
        window.location = "/upgrade"
      }else{
        setStatus(newData.status)
      }
    })

  },[])

  if(status == null) return <LoadingSection/>

  return <Container>{status}</Container>
}