import styled from "styled-components";

const Border = styled.div`
  background: conic-gradient(
    ${({ color }) => color} ${({ deg }) => deg}deg,
    ${({ color2 }) => color2} 3deg
  );
  border-radius: ${({ borderRadius }) => borderRadius};

  width: ${({ mobileSize }) => mobileSize};

  height: ${({ mobileSize }) => mobileSize};

  @media (min-width: 800px) {
    width: ${({ desktopSize }) => desktopSize};
    height: ${({ desktopSize }) => desktopSize};
  }
`;

const ChildContainer = styled.div`
  height: 90%;
  border-radius: 15px;
  margin: 5%;
  display: flex;
  border-radius: ${({ borderRadius }) => borderRadius};
  justify-content: center;
  align-items: center;
  width: 90%;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

export default function CircularProgress({
  children,
  progress,
  mobileSize,
  desktopSize,
  backgroundColor,
  color,
  borderRadius,
  color2,
}) {
  if (!borderRadius) borderRadius = "500px";

  return (
    <Border
      borderRadius={borderRadius}
      deg={progress * 360}
      desktopSize={desktopSize}
      mobileSize={mobileSize}
      color={color}
      color2={color2}
    >
      <ChildContainer
        borderRadius={borderRadius}
        backgroundColor={backgroundColor}
      >
        {children}
      </ChildContainer>
    </Border>
  );
}
