let dummyHozStat = {
  "4/1/2023": { durationInSecs: 15 },
  "4/2/2023": { durationInSecs: 15 },
  "4/3/2023": { durationInSecs: 15 },
  "4/4/2023": { durationInSecs: 15 },
  "4/5/2023": { durationInSecs: 15 },
  "4/6/2023": { durationInSecs: 15 },
  "4/7/2023": { durationInSecs: 15 },
  "4/8/2023": { durationInSecs: 15 },
  "4/9/2023": { durationInSecs: 15 },
  "4/10/2023": { durationInSecs: 15 },
  "4/11/2023": { durationInSecs: 15 },
  "4/12/2023": { durationInSecs: 15 },
  "4/13/2023": { durationInSecs: 30 },
  "4/14/2023": { durationInSecs: 60 },
  "4/15/2023": { durationInSecs: 55 },
  "4/16/2023": { durationInSecs: 75 },
  "4/17/2023": { durationInSecs: 88 },
  "4/18/2023": { durationInSecs: 55 },
  "4/19/2023": { durationInSecs: 55 },
  "4/20/2023": { durationInSecs: 15 },
  "4/21/2023": { durationInSecs: 24 },
  "4/22/2023": { durationInSecs: 15 },
  "4/23/2023": { durationInSecs: 35 },
  "4/24/2023": { durationInSecs: 37 },
  "4/26/2023": { durationInSecs: 15 },
  "4/27/2023": { durationInSecs: 27 },
  "4/28/2023": { durationInSecs: 15 },
  "4/29/2023": { durationInSecs: 45 },
  "4/30/2023": { durationInSecs: 15 },
  "6/1/2023": { durationInSecs: 15 },
  "5/2/2023": { durationInSecs: 15 },
  "5/3/2023": { durationInSecs: 15 },
  "5/4/2023": { durationInSecs: 15 },
  "5/5/2023": { durationInSecs: 15 },
  "5/6/2023": { durationInSecs: 15 },
  "5/7/2023": { durationInSecs: 15 },
  "5/8/2023": { durationInSecs: 15 },
  "5/9/2023": { durationInSecs: 15 },
  "5/10/2023": { durationInSecs: 15 },
  "5/11/2023": { durationInSecs: 15 },
  "5/12/2023": { durationInSecs: 15 },
  "5/13/2023": { durationInSecs: 30 },
  "5/14/2023": { durationInSecs: 60 },
  "5/15/2023": { durationInSecs: 55 },
  "5/16/2023": { durationInSecs: 88 },
  "6/18/2023": { durationInSecs: 55 },
  "5/19/2023": { durationInSecs: 55 },
  "5/20/2023": { durationInSecs: 15 },
  "5/21/2023": { durationInSecs: 24 },
  "5/22/2023": { durationInSecs: 15 },
  "5/23/2023": { durationInSecs: 35 },
  "5/24/2023": { durationInSecs: 37 },
  "5/26/2023": { durationInSecs: 15 },
  "5/27/2023": { durationInSecs: 27 },
  "5/28/2023": { durationInSecs: 15 },
  "5/29/2023": { durationInSecs: 45 },
  "5/30/2023": { durationInSecs: 15 },
  "6/2/2023": { durationInSecs: 15 },
  "6/3/2023": { durationInSecs: 15 },
  "6/4/2023": { durationInSecs: 15 },
  "6/5/2023": { durationInSecs: 15 },
  "6/6/2023": { durationInSecs: 15 },
  "6/7/2023": { durationInSecs: 15 },
  "6/8/2023": { durationInSecs: 15 },
  "6/9/2023": { durationInSecs: 15 },
  "6/10/2023": { durationInSecs: 15 },
  "6/11/2023": { durationInSecs: 15 },
  "6/12/2023": { durationInSecs: 15 },
  "6/13/2023": { durationInSecs: 30 },
  "6/14/2023": { durationInSecs: 60 },
  "6/15/2023": { durationInSecs: 55 },
  "6/16/2023": { durationInSecs: 75 },
  "6/17/2023": { durationInSecs: 88 },
  "6/19/2023": { durationInSecs: 55 },
  "6/20/2023": { durationInSecs: 15 },
  "6/21/2023": { durationInSecs: 24 },
  "6/22/2023": { durationInSecs: 15 },
  "6/23/2023": { durationInSecs: 35 },
  "6/24/2023": { durationInSecs: 37 },
  "6/26/2023": { durationInSecs: 15 },
  "6/27/2023": { durationInSecs: 27 },
  "6/28/2023": { durationInSecs: 15 },
  "6/29/2023": { durationInSecs: 45 },
  "6/30/2023": { durationInSecs: 15 },
};

export default dummyHozStat;
