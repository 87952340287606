import { serverLine } from "./serverLine";

function sendMessageOutsideIframe(type, data) {

  var parent = window.parent;

  if (parent) {
    parent.postMessage({type,data}, "*");
  }

}

export default sendMessageOutsideIframe;
