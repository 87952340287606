import Link from "./Link";
import { useContext } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import Context from "../Context";
import { serverLine } from "../controllers/serverLine";

import LoadingSection from "./LoadingSection";

import { useState } from "react";

import PostsSection from "./PostsSection";

import { Title } from "./UtilityComponents";
import VerticalBarGraph from "./statLibrary/VerticalBarGraph";
import HorizontalBarGraph from "./statLibrary/HorizontalBarGraph";
import dummyHozStat from "../dummyHozStat";
import getDateWithSlash from "../controllers/getDateWithSlash";
import getMonthName from "../controllers/getMonthName";
import getMonthLength from "../controllers/getMonthLength";
import toMinsOrHours from "../controllers/toMinOrHours";
import dummyVerStatProject from "../dummyVerStatProject";
import dummyVerStatUsers from "../dummyVerStatUsers";
import StatOfEachUser from "./StatOfEachUser";
import { useNavigate } from "react-router-dom";
import LineGraph from "./statLibrary/LineGraph";
import hozStatDataProcesssor from "../controllers/hozStatDataProcessor";
import shortenTheName from "../controllers/shortenTheName";
import FeedPage from "./FeedPage";

const Box = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Padding = styled.div`
  padding: 25px;
  background-color: var(--translucent);
  border-radius: 5px;
  width: 100%;
`;

const BoxTitle = styled.h3`
  font-weight: 300;
  margin: 0;
  padding: 0;
  margin-bottom: 25px;
  opacity: 0.7;
`;
const BoxList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;

  @media (min-width: 800px) {
    justify-content: flex-start;
    gap: 50px;
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  gap: 110px;
  width: 100%;
  padding: 0 0;
`;

let colors = [
  "#03a9f4",
  "#FF5722",
  "#4CAF50",
  "#FD8D14",
  "#FF52A2",
  "#7A9D54",
  "#6528F7",
  "#A1CCD1",
];

export default function LoggedInHome() {
  const { loggedInUserID, loggedInUser } = useContext(Context);
  const [homeData, setHomeData] = useState(null);
  let navigate = useNavigate();

  useEffect(() => {
    serverLine.get("/home").then(setHomeData);
  }, []);

  if (!homeData) return <LoadingSection />;
  if (!loggedInUser) return <LoadingSection />;

  console.log(homeData, loggedInUser);

  return (
    <Main>
      <VerticalBarGraph
        title="You Vs Friends"
        rawData={homeData.statOfMeAndFriends}
        dataProcessor={verticalDataProcessorUserBased}
      />
      <LineGraph
        title="You Vs Friends"
        rawData={homeData.statOfMeAndFriends}
        dataProcessor={lineGraphComparisonDataProcessor}
      />
      <VerticalBarGraph
        title="Daily Average"
        disableFiltering={true}
        rawData={homeData.statOfMeAndFriends}
        dataProcessor={dailyAvgDataProcessor}
      />
      {/* <FeedPage/> */}
      {/* <StatOfEachUser statOfMeAndFriends={homeData.statOfMeAndFriends} /> */}
    </Main>
  );

  function lineGraphComparisonDataProcessor({
    timeSpan = { value: "WEEK" },
    xAxis,
    rawData,
    selectedPeoples,
  }) {
    if (!rawData) return [];

    rawData = JSON.parse(JSON.stringify(rawData));

    let compareWith = getTopTwoUsers();

    let maxCompetitions = 2;

    if (window.innerWidth < 900) maxCompetitions = 1;
    compareWith = compareWith.slice(0, maxCompetitions);

    if (selectedPeoples) {
      if (selectedPeoples.length) {
        compareWith = selectedPeoples.map((item) => {
          return { _id: item };
        });
      }
    }

    compareWith = [{ _id: loggedInUserID }, ...compareWith];

    // console.log(rawData);

    for (let i = 0; i < compareWith.length; i++) {
      compareWith[i].color = colors[i];
    }

    let values = [];

    for (let usr of compareWith) {
      let theUser = rawData[usr._id];

      if (theUser) {
        let rawUserStat = theUser.stat;

        let userStat = hozStatDataProcesssor({
          timeSpan,
          xAxis,
          rawData: rawUserStat,
          isMinimal: true,
        });

        if (userStat.data) {
          function processVal(val) {
            let newVal = val / 3600;
            return Math.round(newVal * 10) / 10;
          }

          values.push({
            label: shortenTheName(theUser.userData.name),
            data: userStat.data.map((itm) => processVal(itm.value)),
            borderColor: usr.color,
            backgroundColor: usr.color,
            tension: 0.2,
            borderWidth: 3,
            pointRadius: 1.5,
            fill: usr.color,
          });
        }
      }
    }

    console.log(values);

    return values;
  }

  function getTopTwoUsers() {
    let competitions = [];
    for (let userID in homeData.statOfMeAndFriends) {
      let usr = homeData.statOfMeAndFriends[userID];
      if (userID !== loggedInUserID) {
        competitions.push({ _id: userID, score: usr.userData.dailyAvgScore });
      }
    }

    competitions.sort((a, b) => {
      return b.score - a.score;
    });

    return competitions;
  }

  function dailyAvgDataProcessor({
    dataPoints,
    startDate,
    timeSpanType,
    rawData,
  }) {
    let proj = {};

    for (let userID in rawData) {
      let user = rawData[userID];
      let name = user.userData.name;
      let username = user.userData.username;

      let time = user.userData.dailyAvgScore;

      if (!time) continue;
      proj[userID] = {
        onClick: () => {
          navigate("/" + username);
        },
        label: `${toMinsOrHours({
          unparsedSeconds: time,
        })} . ${shortenTheName(name)}`,
        value: time,
      };
    }

    return proj;
  }

  function verticalDataProcessorUserBased({
    dataPoints,
    startDate,
    timeSpanType,
    rawData,
  }) {
    let proj = {};
    // console.log(rawData, "%%%%%%%%");
    let date = new Date(startDate.toString());

    if (timeSpanType === "EVER") {
      for (let userID in rawData) {
        let user = rawData[userID];
        let name = user.userData.name;
        let username = user.userData.username;
        let totalTimeOfUser = 0;

        for (let slashDate in user.stat) {
          let item = user.stat[slashDate];
          totalTimeOfUser += item;
        }

        let time = totalTimeOfUser;

        proj[userID] = {
          onClick: () => {
            navigate("/" + username);
          },
          label: `${toMinsOrHours({
            unparsedMinutes: time,
          })} . ${shortenTheName(name, 15)}`,
          value: time,
        };
      }

      return proj;
    } else {
      // console.log(timeSpanType, dataPoints);
      for (let i = 0; i < dataPoints; i++) {
        let slashDate = getDateWithSlash(date);
        processDate(slashDate);
        date.setDate(date.getDate() + 1);
      }
    }

    function pushData({ name, username, item }) {
      if (!item) return;
      if (proj[name]) {
        proj[name].value += item;
        proj[name].label = `${toMinsOrHours({
          unparsedMinutes: proj[name].value,
        })} . ${shortenTheName(name, 15)}`;
      } else {
        proj[name] = {
          onClick: () => {
            navigate("/" + username);
          },
          value: item,
          label: `${toMinsOrHours({
            unparsedMinutes: item,
          })} . ${shortenTheName(name, 15)}`,
        };
      }
    }

    function processDate(slashDate) {
      for (let userID in rawData) {
        let user = rawData[userID];
        let name = user.userData.name;
        let username = user.userData.username;

        if (user.stat[slashDate]) {
          let item = user.stat[slashDate];

          pushData({ name, item, username });
        }
      }
    }

    // console.log(proj);
    return proj;
  }
}
