import styled from "styled-components";
import goToAuthScreen from "../../controllers/goToAuthScreen";
import { HiMenuAlt4 } from "react-icons/hi";
import { GrGoogle } from "react-icons/gr";
import { useContext } from "react";
import Context from "../../Context";
import { FaGooglePlay } from "react-icons/fa";
import LogoSVG from "../LogoSVG";
import BrandContainer from "../BrandContainer";
import { VscCode, VscExtensions } from "react-icons/vsc";

const ParentContainer = styled.div`
  display: flex;
  width: 100vw;

  background-size: cover;

  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */

  /* @media (max-width: 1200px) {
    flex-direction: column;
  } */

  @media (max-width: 700px) {
    background-image: unset;
    /* padding: 25px; */
    /* align-items: flex-start; */
  }
`;

const TheImage = styled.img`
  width: 30vw;
  height: auto;
  object-fit: cover;
  filter: grayscale(0.2);

  @media (max-width: 700px) {
    display: none;
  }

  ${({ colorMode }) => {
    if (colorMode == "LIGHT")
      return `
      filter:invert(1);
    
    `;
  }}
`;

const Confetti = styled.img`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  object-fit: cover;

  pointer-events: none;
  /* opacity: 0.5; */
  @media (max-width: 900px) {
    display: none;
  }

  filter: grayscale(1);

  ${({ colorMode }) => {
    if (colorMode == "LIGHT")
      return `
      filter:invert(1) grayscale(1);
    
    `;
  }}
`;

const Container = styled.div`
  width: 62vw;
  margin: 50px 0;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 50px;

  @media (max-width: 700px) {
    width: ${() =>
      localStorage.getItem("extension-login") ? "100vw" : "90vw"};
  }
`;

const Tagline = styled.div`
  font-weight: 900;
  font-family: "Source Code Pro", monospace;
  letter-spacing: -3px;
  font-size: 30px;
  /* background: linear-gradient(to bottom, var(--color), var(--color), #000); */
  /* -webkit-text-fill-color: transparent; */
  /* -webkit-background-clip: text; */

  text-align: center;
  width: 100%;
  @media (max-width: 1200px) {
    font-size: 35px;
  }
`;

const Brand = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  height: 40px;
  width: 40px;
  /* width: 159px; */
  margin: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/home/logo.svg");
`;

const MainButtons = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: center;
  align-items: center;
  border: none;

  width: 100%;
  position: fixed;
  bottom: 50px;
  left: 0;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const Button = styled.button`
  width: auto;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;

  justify-content: center;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid;
  gap: 10px;
  background-color: var(--color);
  cursor: pointer;
  transition: 0.25s ease-in-out;
  color: var(--bgColor);
  &:hover {
    transform: scale(0.9);
  }

  @media (min-width: 950px) {
    width: 300px;
  }
`;

const BigButton = styled(Button)`
  width: 200px;
  flex: unset;
  border: none;
  box-shadow: 3px 5px 8px 0px #00000066;
`;

const DownloadAppButton = styled(Button)`
  @media (min-width: 950px) {
    display: none;
  }
`;

const H1 = styled.h1`
  font-weight: 900;
  margin-top: 0;
  font-size: 24px;
  width: auto;
  width: 100%;
  margin: 0;

  @media (min-width: 920px) {
    font-size: 24px;
  }
`;
const Medium = styled.div`
  font-size: 16px;
  opacity: 0.7;
  font-weight: 400;
  width: 38vw;
  line-height: 28px;
  text-align: center;

  @media (max-width: 1200px) {
    width: 62vw;
    font-size: 18px;
  }

  @media (max-width: 700px) {
    width: ${() =>
      localStorage.getItem("extension-login") ? "100vw" : "90vw"};
    font-size: 18px;
  }
`;

const Words = styled.div`
  margin-top: 0;
  display: flex;
  margin-top: 0;
  gap: 80px;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

//A devlog/studylog/artlog Platform

const Icon = styled.img`
  height: 18px;
`;

export default function LoggedOutHomePage() {
  let { setForm, colorMode } = useContext(Context);

  let date = new Date();

  // return (
  //   <>
  //     {date.getDate()} / {date.getMonth() + 1} / {date.getFullYear()}
  //   </>
  // );

  return (
    <ParentContainer>
      <Confetti src={"/confetti.png"} colorMode={colorMode} />
      <Container>
        <Words>
          <BrandContainer />

          <TextContainer>
            <TheImage src="/heroImage.png" />
            <Tagline>A social time tracker for VsCode</Tagline>
            <Medium>
              So, basically you install the VsCode extension and then you can
              see how many hours your friends have clocked vs how many hours you
              have clocked and on what project.
            </Medium>
          </TextContainer>
        </Words>

        <MainButtons>
          <BigButton onClick={goToAuthScreen}>
            <GrGoogle />
            Login With Google
          </BigButton>

          <BigButton
            onClick={() => {
              window.open(
                "https://marketplace.visualstudio.com/items?itemName=upon-interactive.paratime"
              );
            }}
          >
            <Icon
              style={{
                filter:
                  localStorage.getItem("paratime-color-mode") === "LIGHT"
                    ? "invert(1)"
                    : "",
              }}
              src="/vscode.svg"
            />
            Install Extension
          </BigButton>

          <Button
            onClick={openMoreMenu}
            style={{
              width: "40px",
              height: "40px",
              fontSize: "21px",
              backgroundColor: "var(--bgColor)",
              color: "var(--color)",
              flex: "unset",
              opacity: 0.7,
            }}
          >
            <HiMenuAlt4 />
          </Button>
        </MainButtons>
      </Container>
    </ParentContainer>
  );

  function openApp() {
    // window.location = playstore link
  }

  function open(link) {
    return () => {
      return (window.location = link);
    };
  }

  function openMoreMenu() {
    setForm({
      options: [
        {
          name: "About us",
          onClick: open("https://arnav.upon.one"),
        },
      ],
    });
  }
}
