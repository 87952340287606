import Link from "./Link";
import styled from "styled-components";

import getStatColor from "../controllers/getStatColor";
import getMaxTime from "../controllers/getMaxTime";
import toMinsOrHours from "../controllers/toMinOrHours";
import getDateString from "../controllers/getDateString";
import { AiOutlineEdit } from "react-icons/ai";

const StatRow = styled.div`
  /* height: 300px; */
  width: 100%;
  color: var(--color);
  display: flex;
  gap: 25px;
  align-items: flex-end;
  flex-direction: row;
`;
const Padding = styled.div`
  padding: 20px 25px;
  background-color: var(--translucent);
  border-radius: 15px;
  width: 100%;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70px;
  gap: 15px;
  border-radius: 5px 5px 0 0;
`;

const ColumnBox = styled.div`
  border-radius: 15px 15px 3px 3px;
  overflow: hidden;
`;
const Box = styled.div`
  width: 70px;
`;

const DateStr = styled.div``;
const TotalTime = styled.div``;

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 50px;
`;

const Title = styled.div`
  font-size: 21px;
  font-weight: 300;
`;
const TagsDefinition = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 25px;
`;
const TagsDefi = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;
`;
const TagColor = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 25px;
`;
const TagName = styled.div``;

const EditTags = styled.div`
  cursor: pointer;
  background: var(--translucent);
  padding: 0;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
`;

export default function Stat({ userData }) {
  return (
    <Padding>
      <TitleRow>
        <Title>Time Clocked</Title>

        <TagsDefinition>{getTagsDefi()}</TagsDefinition>
      </TitleRow>

      <StatRow>{getStatBoxes()}</StatRow>
    </Padding>
  );

  function getStatBoxes() {
    let data = [];

    if (!userData) return data;

    let today = new Date();
    let thisMonth = today.getMonth();

    let theList = userData.dailyUsageStat;

    if (!theList) return [];

    if (theList.length > 10) {
      theList = Object.keys(theList)
        .map(function (key) {
          return theList[key];
        })
        .slice(theList.length - 10, theList.length);
    }

    // if (typeof window !== undefined) {
    //   if (window.innerWidth <= 800) {
    //     if (theList.length > 5) {
    //       theList = Object.keys(theList)
    //         .map(function (key) {
    //           return theList[key];
    //         })
    //         .slice(theList.length - 5, theList.length);
    //     }
    //   }
    // }

    let max = getMaxTime(theList);

    for (let key in theList) {
      let maxValAndList = theList[key];
      let date = new Date(key);
      if (thisMonth !== date.getMonth()) continue;

      let TheDate = getDateString(key);

      data.push(
        <StatBox
          key={TheDate}
          projects={userData.projects}
          maxValue={max}
          dateString={TheDate}
          data={maxValAndList}
        />
      );
    }

    return data;
  }

  function getTagsDefi() {
    let projects = { 0: { name: "Work" } };
    let toRender = [];

    if (userData.projects) {
      projects = { ...projects, ...userData.projects };
    }

    for (let projectID in projects) {
      let theTag = projects[projectID];

      toRender.push(
        <TagsDefi key={projectID}>
          <TagColor
            style={{ backgroundColor: getStatColor(projectID, projects) }}
          />
          <TagName>{theTag.name}</TagName>
        </TagsDefi>
      );
    }

    toRender.push(
      <Link key={"editTag"} href="/edit-projects">
        <EditTags>
          <AiOutlineEdit />
        </EditTags>
      </Link>
    );

    return toRender;
  }
}

function StatBox({ maxValue, data, dateString, projects }) {
  let totalValue = data.totalDurationInSecs;
  let list = data.content;

  totalValue = toMinsOrHours({ unparsedSeconds: totalValue });

  let maxHeight = 200;

  let toRender = [];

  let i = 0;

  for (let item of list) {
    let height = (item.duration / maxValue) * maxHeight;
    let bgColor = getStatColor(item.projectID, projects);

    i++;

    toRender.push(
      <Box
        key={i}
        style={{ height: height + "px", backgroundColor: bgColor }}
      ></Box>
    );
  }

  return (
    <Column>
      <TotalTime>{totalValue}</TotalTime>
      <ColumnBox>{toRender}</ColumnBox>
      <DateStr>{dateString}</DateStr>
    </Column>
  );
}
