import Link from "../Link";
import styled, { keyframes } from "styled-components";

import getStatColor from "../../controllers/getStatColor";
import getMaxTime from "../../controllers/getMaxTime";
import toMinsOrHours from "../../controllers/toMinOrHours";
import getDateString from "../../controllers/getDateString";
import { AiOutlineEdit } from "react-icons/ai";
import shadeColor from "../../controllers/shadeColor";
import Select from "react-select";
import { useEffect, useState } from "react";
import CustomSelect from "../CustomSelect";
import GraphDurationSelect from "./GraphDurationSelect";
import getDateRange from "../../controllers/statLibrary/getDateRange";
import GraphDurationPaginator from "./GraphDurationPaginator";
import { useNavigate } from "react-router-dom";
import getDateWithSlash from "../../controllers/getDateWithSlash";
import VerticalBarGraph from "./VerticalBarGraph";

const Padding = styled.div`
  width: 100%;
`;

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-item: center;
  margin-bottom: 25px;

  @media (max-width: 900px) {
    /* display: none; */
    flex-direction: column;
    gap: 25px;
    align-items: flex-start;
  }
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 300;
  display: flex;
  justify-content: center;
  align-items: center;
  /* opacity: 0.8; */
  text-transform: capitalize;

  @media (max-width: 900px) {
    /* display: none; */
  }
`;

const Columns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 10px;
  gap: 5px;
  background: var(--translucent);
  overflow: hidden;
`;

const scaleBar = (width) => keyframes`
    0% {
      width : 0; 
    }
    100% {
        width : ${width};
    }
`;

const ColorfulBar = styled.div`
  height: 30px;
  background: steelblue;
  border-radius: 5px 25px 25px 5px;
  animation: ${(props) => scaleBar(props.width)} 0.25s ease-out;
`;

const Label = styled.div`
  opacity: 0.5;
  text-transform: capitalize;
  ${({ hasLink }) => {
    if (hasLink)
      return `
      cursor:pointer;
      &:hover{
        text-decoration: underline;
      }
    `;
  }}
`;

const Actions = styled.div`
  display: flex;
  gap: 15px;
`;

const Message = styled.div``;

const Box = styled.div``;
const Time = styled.div``;
const Duration = styled.div``;

let diffAllowed = 30;

export default function TimeStat({ title = "Demo Stat", rawData }) {
  if (!rawData) return null;

  return (
    <VerticalBarGraph
      title={title}
      dataProcessor={timeStatDataProcessor}
      rawData={rawData}
      disableSorting={true}
      disableType={true}
    />
  );

  function timeStatDataProcessor({ startDate }) {
    let stat = processData(startDate);

    if (!stat) return null;

    let toReturn = {};

    for (let grp of stat) {
      let minDiff = Math.abs(grp.endTime - grp.startTime);
      if (minDiff === 0) continue;

      // if(grp.value > minDiff) grp.value = minDiff;
      toReturn[grp.startTime] = {
        value: grp.value,
        label: `${timeIntToStr(grp.startTime)} - ${timeIntToStr(grp.endTime)}`,
      };
    }

    return toReturn;
  }

  function timeIntToStr(theInt) {
    if (theInt < 0) theInt = 0;
    let hour = Math.floor(theInt / 60);
    let mins = theInt % 60;
    let amPm = "AM";

    // console.log(theInt < )

    if (hour >= 12) {
      amPm = "PM";
    }

    if (!hour) hour = 12;

    if (hour >= 13) {
      hour = hour - 12;
    }

    return `${hour}:${mins} ${amPm}`;
  }

  function processData(theDate) {
    let today = getDateWithSlash(theDate);
    if (!rawData[today]) return null;
    let sessionData = rawData[today].sessionData;
    if (!sessionData) return null;
    let allSessionTimeStat = getAllSessionTimeStat(sessionData);
    let groupedStat = groupStat(allSessionTimeStat);
    console.log(groupedStat);
    return groupedStat;
  }

  function groupStat(allSessionTimeStat) {
    let groups = [];
    let lastTime = 0;

    for (let item of allSessionTimeStat) {
      if (!lastTime) {
        lastTime = item.int;
        groups.push({ startTime: item.int - 5, endTime: item.int, value: 5 });
        continue;
      }

      let diff = getTimeDiff(item.int, lastTime);

      if (diff > diffAllowed) {
        groups.push({ startTime: item.int - 5, endTime: item.int, value: 5 });
      } else {
        groups[groups.length - 1].endTime = item.int;
        groups[groups.length - 1].value += 5;
      }

      lastTime = item.int;
    }

    return groups;
  }

  function getTimeDiff(itm1, itm2) {
    return Math.abs(itm1 - itm2);
  }

  function timeStrToMins(timeStr) {
    let timeSplit = timeStr.split(":");
    if (!timeSplit[0] && !timeSplit[1]) return 0;
    let hrs = parseInt(timeSplit[0]);
    let mins = parseInt(timeSplit[1]);

    return hrs * 60 + mins;
  }

  function getAllSessionTimeStat(sessionData) {
    let timeStat = {};

    for (let sessionID in sessionData) {
      let session = sessionData[sessionID];
      if (session.timeStat) {
        timeStat = { ...timeStat, ...session.timeStat };
      }
    }

    return sortTime(Object.keys(timeStat));
  }

  function sortTime(timeArray) {
    let newArray = [];

    for (let item of timeArray) {
      newArray.push({ str: item, int: timeStrToMins(item) });
    }

    newArray.sort(function (a, b) {
      return a.int - b.int;
    });

    return newArray;
  }
}
