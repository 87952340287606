import styled from "styled-components";
import getImageURL from "../controllers/getImageURL";
import Link from "./Link";
import toMinsOrHours from "../controllers/toMinOrHours";
import { serverLine } from "../controllers/serverLine";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { useContext } from "react";
import Context from "../Context";
import { useState } from "react";
import { TbExternalLink } from "react-icons/tb";
import PostBox from "./PostBox";

const Grid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 25px;
  padding: 0;
  flex-wrap: wrap;
  width: 100%;
`;

export default function ProfilePostsGrid({ posts }) {
  let images = posts.map((item, i) => <PostBox item={item} key={i} />);

  return <Grid>{images}</Grid>;
}
