import { serverLine } from "../controllers/serverLine";
import LoadingSection from "./LoadingSection";

import styled from "styled-components";
import { useContext, useState } from "react";

import { useNavigate } from "react-router-dom";
import Context from "../Context";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 50px;
`;
const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 15px;
`;
const Label = styled.div``;
const List = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
  gap: 25px;
`;

const SubmitButton = styled.div`
  background-color: rgba(255, 255, 225, 0);
  border: 1px solid;
  color: var(--color);
  padding: 10px 25px;
  border-radius: 25px;
  cursor: pointer;

  &:hover {
    background: var(--color);
    color: var(--bgColor);
  }
`;

const TagCreator = styled.div`
  display: flex;
  flex-direction: row;
  height: 50px;
  gap: 0;

  overflow: hidden;
  border-radius: 50px;
  background: var(--translucent);
`;
const NewTagInput = styled.input`
  border: 15px;
  padding: 0 15px;
  background: transparent;
  outline: none;
  height: 50px;
  color: var(--color);
`;
const PlusButton = styled.button`
  border: 15px;
  background: transparent;
  color: var(--color);
  width: 50px;
  height: 50px;
  border-left: 1px solid var(--translucent);
  cursor: pointer;

  &:hover {
    background: var(--color);
    color: var(--bgColor);
  }
`;

const Tags = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 25px;
`;

const Button = styled.button`
  background-color: rgba(255, 255, 225, 0);
  border: 1px solid;
  color: var(--color);
  padding: 10px 25px;
  border-radius: 25px;
  cursor: pointer;
  opacity: 0.7;

  &:hover {
    background: var(--color);
    color: var(--bgColor);
  }

  ${({ highlight }) => {
    if (highlight) {
      return `
        background-color:var(--color);
        color: var(--bgColor);
        opacity:1;
        `;
    }
  }}
`;

export default function CreateTimer() {
  const { loggedInUser, updateLoggedInUser } = useContext(Context);
  const [selectedDuration, setDuration] = useState(30);
  const [selectedTagID, setSelectedTagID] = useState(0);
  const [newTagName, setNewTagName] = useState("");
  const [newTags, setNewTags] = useState({});
  const navigate = useNavigate();

  let availableDurations = [30, 45, 90];

  let durationsButtons = [];

  if (!loggedInUser) return <LoadingSection />;

  for (let item of availableDurations) {
    durationsButtons.push(
      <Button
        onClick={() => {
          setDuration(item);
        }}
        highlight={selectedDuration === item}
      >
        {item} Mins
      </Button>
    );
  }

  return (
    <Container>
      <Box>
        <Label>Select Duration</Label>
        <List>{durationsButtons}</List>
      </Box>

      <Box>
        <Label>Select Project</Label>
        <List>{renderTags()}</List>
      </Box>

      <SubmitButton onClick={createTimer}>Create Timer</SubmitButton>
    </Container>
  );

  function createTimer() {
    navigate(
      `/session/?duration=${selectedDuration}&projectID=${selectedTagID}`
    );
  }

  function updateNewTag(e) {
    setNewTagName(e.target.value);
  }

  function renderTags() {
    let projects = [];
    let allProjects = { 0: { name: "Work" } };

    if (loggedInUser.projects) {
      allProjects = { ...loggedInUser.projects };
      allProjects[0] = { name: "Work" };
    }

    allProjects = { ...allProjects, ...newTags };

    for (let projectID in allProjects) {
      let theTag = allProjects[projectID];

      projects.push(
        <Button
          onClick={() => {
            setSelectedTagID(projectID);
          }}
          highlight={projectID.toString() === selectedTagID.toString()}
        >
          {theTag.name}
        </Button>
      );
    }

    projects.push(
      <TagCreator>
        <NewTagInput
          placeholder="Type New Tag Here"
          value={newTagName}
          onChange={updateNewTag}
        />
        <PlusButton onClick={createNewTag}>+</PlusButton>
      </TagCreator>
    );

    return projects;
  }

  async function createNewTag() {
    let newTagID = Math.round(Math.random() * 100000);

    let theTagName = newTagName;

    setNewTags({ ...newTags, [newTagID]: { name: newTagName } });
    setSelectedTagID(newTagID);
    setNewTagName("");

    await serverLine.patch("/profile", {
      changes: { project: { projectID: newTagID, name: theTagName } },
    });

    updateLoggedInUser();
  }
}
