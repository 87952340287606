import { useContext } from "react";
import styled from "styled-components";
import Context from "../Context";
import { useEffect, useState } from "react";
import LoadingSection from "./LoadingSection";
import { serverLine } from "../controllers/serverLine";
import FollowerOrFollowingBox from "./FollowerOrFollowingBox";
import UserPageTopSection from "./UserPageTopSection";
import { useParams } from "react-router-dom";
import getDateWithSlash from "../controllers/getDateWithSlash";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
`;
const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;

const SubTitle = styled.h3`
  text-transform: capitalize;
  margin: 0;
`;

const Hr = styled.div`
  height: 1px;
  width: 100%;
  margin: 0px 0;
  background: var(--translucentHard);
`;

export default function Leaderboard() {
  const [data, setData] = useState(null);

  useEffect(() => {
    setData(null);
    serverLine
      .get(`/leaderboard/?date=${getDateWithSlash(new Date())}`)
      .then((newData) => {
        setData(newData);
      });
  }, []);

  if (!data) return <LoadingSection />;

  return (
    <List>
      {data.map((item) => (
        <FollowerOrFollowingBox user={item} />
      ))}
    </List>
  );
}
