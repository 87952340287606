import { useContext } from "react";
import styled from "styled-components";
import Context from "../Context";
import { useEffect, useState } from "react";
import LoadingSection from "./LoadingSection";
import { serverLine } from "../controllers/serverLine";
import FollowerOrFollowingBox from "./FollowerOrFollowingBox";
import UserPageTopSection from "./UserPageTopSection";
import { useParams } from "react-router-dom";
import getImageURL from "../controllers/getImageURL";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;

const SubTitle = styled.h3`
  text-transform: capitalize;
  margin: 0;
`;

const Hr = styled.div`
  height: 1px;
  width: 100%;
  margin: 0px 0;
  background: var(--translucentHard);
`;

const ProfileImage = styled.img`
  height: 100px;
  width: 100px;
  object-fit: cover;
`;

export default function ProfilePicture({ size, user, borderRadius = "500px" }) {
  let style = { borderRadius };

  if (size) {
    style = { height: size, width: size, borderRadius };
  }

  return user.profileImage ? (
    <ProfileImage style={style} src={getImageURL(user.profileImage)} />
  ) : (
    <ProfileImage style={style} src={"/defaultProfile.png"} />
  );
}
