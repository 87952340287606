import productionProducts from "../data/productionProducts"
import virtualProducts from "../data/virtualProducts"
import getPackageExpiry from "./getPackageExpiry"

export default function getCurrentPackage(loggedInUser){


    if(!loggedInUser) return null

    if(!loggedInUser.payments) return null
  
    let thePayment = loggedInUser.payments[0]
  
    if(!thePayment) return null

    let expiryDate = getPackageExpiry(thePayment)
  
    if(!expiryDate.value) return null

    let productList = virtualProducts
  
    if(process.env.NODE_ENV == "production" || process.env.REACT_APP_ENV_TYPE === "production" ){
      productList = productionProducts
    }

    

    if(thePayment.productID == productList[0].id) return [productList[0], thePayment, expiryDate.string]
    if(thePayment.productID == productList[1].id) return [productList[1], thePayment, expiryDate.string]
}

