import toMinsOrHours from "./toMinOrHours";

export default function hozStatDataProcesssor({
  timeSpan = { value: "WEEK" },
  xAxis,
  rawData,
  projectID_filter,
  isMinimal
}) {
  let processedData = [];
  let totalValue = 0;

  let newRawData = mergeDataPoints(timeSpan.value, rawData, projectID_filter, isMinimal);

  for (let xAxisDataPoint of xAxis) {
    let theDate = xAxisDataPoint.when;

    let item = newRawData[theDate];
    let theValue = 0;

    if (item) {

      if(isMinimal){
        theValue =item *60 ;
      }else{
        
        theValue = item.totalDurationInSecs;
      }
    

      totalValue += theValue;
    }

    let bottomLabel = xAxisDataPoint.label;

    if(isNaN(theValue)) theValue = 0

    processedData.push({
      value: theValue,
      altInfo: toMinsOrHours({ unparsedSeconds: theValue }),
      bottomLabel: bottomLabel,
    });
  }

  let totalTime = toMinsOrHours({ unparsedSeconds: totalValue });

  return { data: processedData, totalTime };
}

function mergeDataPoints(timeSpanType, rawData, projectID_filter,isMinimal) {
  rawData = JSON.parse(JSON.stringify(rawData));

  if (projectID_filter) {
    for (let dateStr in rawData) {
      let dateStat = rawData[dateStr];
      let newDuration = 0;
      for (let sessionID in dateStat.sessionData) {
        let session = dateStat.sessionData[sessionID];
        if (session.projectID === projectID_filter) {
          newDuration += session.durationInSecs;
        }
      }

      dateStat.totalDurationInSecs = newDuration;
    }
  }

  if (timeSpanType === "WEEK" || timeSpanType === "MONTH" || !timeSpanType) {
    return rawData;
  } else if (timeSpanType === "YEAR") {
    let newRawData = {};

    for (let date in rawData) {
      let item = rawData[date];
      let dateObj = new Date(date);

      let monthAndYear = `${dateObj.getMonth() + 1}/${dateObj.getFullYear()}`;

      if (newRawData[monthAndYear]) {

        if(isMinimal){
          newRawData[monthAndYear] +=  item;
        }else{
          newRawData[monthAndYear].totalDurationInSecs +=  item.totalDurationInSecs;
        }

        
      } else {

        if(isMinimal){
          newRawData[monthAndYear] =  item
        }else{
          newRawData[monthAndYear] = {
            totalDurationInSecs: isMinimal? item: item.totalDurationInSecs
          };
        }
     
      }
    }

    return newRawData;
  } else if (timeSpanType === "EVER") {
    let newRawData = {};

    for (let date in rawData) {
      let item = rawData[date];
      let dateObj = new Date(date);

      let year = dateObj.getFullYear();

      if (newRawData[year]) {

        if(isMinimal){
          newRawData[year] += item;
        }else{
          newRawData[year].totalDurationInSecs += item.totalDurationInSecs;
        }
       
      } else {
        if(isMinimal){
          newRawData[year] = item.totalDurationInSecs ;
        }else{
          newRawData[year] = { totalDurationInSecs: item.totalDurationInSecs };
        }
       
      }
    }

    return newRawData;
  }
}
