import Link from "./Link";
import { useContext } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import Context from "../Context";
import { serverLine } from "../controllers/serverLine";

import LoadingSection from "./LoadingSection";

import { useState } from "react";

import PostsSection from "./PostsSection";

import { Title } from "./UtilityComponents";
import VerticalBarGraph from "./statLibrary/VerticalBarGraph";
import HorizontalBarGraph from "./statLibrary/HorizontalBarGraph";
import dummyHozStat from "../dummyHozStat";
import getDateWithSlash from "../controllers/getDateWithSlash";
import getMonthName from "../controllers/getMonthName";
import getMonthLength from "../controllers/getMonthLength";
import toMinsOrHours from "../controllers/toMinOrHours";
import dummyVerStatProject from "../dummyVerStatProject";
import dummyVerStatUsers from "../dummyVerStatUsers";
import StatOfEachUser from "./StatOfEachUser";
import { useNavigate } from "react-router-dom";
import LineGraph from "./statLibrary/LineGraph";
import hozStatDataProcesssor from "../controllers/hozStatDataProcessor";
import shortenTheName from "../controllers/shortenTheName";

const Box = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Padding = styled.div`
  padding: 25px;
  background-color: var(--translucent);
  border-radius: 5px;
  width: 100%;
`;

const BoxTitle = styled.h3`
  font-weight: 300;
  margin: 0;
  padding: 0;
  margin-bottom: 25px;
  opacity: 0.7;
`;
const BoxList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;

  @media (min-width: 800px) {
    justify-content: flex-start;
    gap: 50px;
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  gap: 25px;
  width: 100%;
  padding: 0 0;
`;

let colors = ["#03a9f4", "#FF5722", "#4CAF50","#FD8D14","#FF52A2","#7A9D54","#6528F7","#A1CCD1"];

export default function FeedPage() {
  const { loggedInUserID } = useContext(Context);
  const [feed, setFeed] = useState(null);

  useEffect(() => {
    serverLine.get("/feed").then(setFeed);
  }, []);

  if (!feed) return [];


  return (
    <Main>
      
        <Title>Feed</Title>
        <PostsSection posts={feed} />
      
    </Main>
  );


}
