import { styled } from "styled-components";
import BrandContainer from "./BrandContainer";
import { useContext, useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import selectFile from "../controllers/selectFile";
import compressAndUploadFile from "../controllers/compressAndUploadFile";
import { serverLine } from "../controllers/serverLine";
import LoadingSection from "./LoadingSection";
import Context from "../Context";
import getImageURL from "../controllers/getImageURL";

import { GrDown } from "react-icons/gr";
import PopupSelect from "./PopupSelect";
import { BsArrowDown } from "react-icons/bs";
import { AiOutlineDown } from "react-icons/ai";
import PopupLocationSelector from "./PopupLocationSelector";
import CustomToggle from "./CustomToggle";
import ProfilePicture from "./ProfilePicture";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 0;
  gap: 25px;
  align-items: center;
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: flex-start;
`;

const Button = styled.div`
  width: 38vw;
  font-size: 18px;
  border: 1px solid var(--translucent);
  background: var(--translucent);
  border-radius: 15px;
  color: var(--color);
  text-align: center;
  cursor: pointer;
  padding: 20px 15px;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const Textarea = styled.textarea`
  width: 38vw;
  font-size: 18px;
  border: 1px solid var(--translucent);
  background: var(--translucent);
  border-radius: 15px;
  color: var(--color);
  /* text-align: center; */
  font-family: "Inter";
  resize: none;

  padding: 15px 15px;

  @media (max-width: 900px) {
    width: 90vw;
  }

  &::placeholder {
    color: var(--translucentHard);
  }
`;

const Input = styled.input`
  width: 38vw;
  font-size: 18px;
  border: 1px solid var(--translucent);
  background: var(--translucent);
  border-radius: 15px;
  color: var(--color);
  /* text-align: center; */
  font-family: "Inter";
  padding: 15px 15px;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const PopupSelectInput = styled.div`
  width: 38vw;
  font-size: 18px;
  border: 1px solid var(--translucent);
  background: var(--translucent);
  border-radius: 15px;
  color: var(--color);
  text-align: center;
  font-family: "Inter";
  padding: 15px 15px;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const SubmitButton = styled.div`
  background: var(--translucent);
  padding: 15px 50px;
  border-radius: 15px;
  cursor: pointer;
  display: flex;
  font-size: 21px;
  margin-top: 50px;
  justify-content: center;
  align-items: center;

  transition: 0.25s ease-out;

  @media (max-width: 900px) {
    width: 90vw;
  }

  &:hover {
    transform: scale(0.9);
  }
`;

const Label = styled.div`
  margin-bottom: -15px;
  opacity: 0.5;
`;

const SelectedImage = styled.img`
  width: 120px;
  height: 120px;
  object-fit: cover;
  /* height: auto; */
  /* height: 300px; */
  border-radius: 10px;
`;

const ImageUploadSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: center;
`;

const ImageUploadButton = styled(Button)`
  /* width: 300px; */
`;

export default function ProfileEditor({ callback }) {
  const { loggedInUser, setLoggedInUser } = useContext(Context);
  const [bio, setBio] = useState(null);
  const [selectedImages, setSelectedImages] = useState(null);
  const [githubHandle, setGithubHandle] = useState(null);
  const [websiteLink, setWebsiteLink] = useState(null);
  const [country, setCountry] = useState(null);
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);
  const [hireable, setHireable] = useState(false);
  const [gender, setGender] = useState(false);
  const [username, setUsername] = useState(false);
  const [privacy, setPrivacy] = useState("PUBLIC");
  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  const inputRef = useRef(null);

  useEffect(() => {
    if (loggedInUser) {
      if (loggedInUser.bio) setBio(loggedInUser.bio);
      if (loggedInUser.githubHandle) setGithubHandle(loggedInUser.githubHandle);
      if (loggedInUser.websiteLink) setWebsiteLink(loggedInUser.websiteLink);
      if (loggedInUser.country) setCountry(loggedInUser.country);
      if (loggedInUser.state) setState(loggedInUser.state);
      if (loggedInUser.city) setCity(loggedInUser.city);
      if (loggedInUser.hireable) setHireable(loggedInUser.hireable);
      if (loggedInUser.privacy) setPrivacy(loggedInUser.privacy);
      if (loggedInUser.gender) setGender(loggedInUser.gender);
      if (loggedInUser.username) setUsername(loggedInUser.username);
    }
  }, [loggedInUser]);

  const resizeTextArea = () => {
    if (inputRef.current) {
      inputRef.current.style.height = "auto";
      inputRef.current.style.height = inputRef.current.scrollHeight + "px";
    }
  };

  useEffect(resizeTextArea, [bio]);

  if (loading || !loggedInUser) return <LoadingSection />;

  let profilePicObj = selectedImages ? (
    <ShowSelectedImage fileObject={selectedImages[0]} />
  ) : (
    <ProfilePicture user={loggedInUser} size="120px" />
  );

  if (!profilePicObj) {
    if (loggedInUser.profileImage) {
      profilePicObj = (
        <SelectedImage src={getImageURL(loggedInUser.profileImage)} />
      );
    }
  }

  return (
    <>
      <Inputs>
        <ImageUploadSection>
          {profilePicObj}
          <ImageUploadButton onClick={chooseImage}>
            {selectedImages ? "Change" : "Upload"} Profile Picture
          </ImageUploadButton>
        </ImageUploadSection>

        <Label>Username</Label>
        <Input
          placeholder="username"
          value={username}
          onChange={({ target }) => {
            setUsername(target.value.toLowerCase());
          }}
        ></Input>

        <Label>Bio</Label>
        <Textarea
          ref={inputRef}
          placeholder="Describe Yourself and your skills"
          value={bio}
          onChange={({ target }) => {
            setBio(target.value);
          }}
        ></Textarea>

        <Label>Github Username</Label>
        <Input
          placeholder="Type your Github Username"
          value={githubHandle}
          onChange={({ target }) => {
            setGithubHandle(target.value);
          }}
        />

        <Label>Website Link</Label>
        <Input
          placeholder="Type Your Website Link"
          value={websiteLink}
          onChange={({ target }) => {
            setWebsiteLink(target.value);
          }}
        />

        <Label>Country</Label>
        <SelectInput
          onChange={setCountry}
          type="COUNTRY"
          placeholder={"Select Country"}
          value={country}
        />

        <Label>State</Label>
        <SelectInput
          onChange={setState}
          type="STATE"
          country={country}
          placeholder={"Select State"}
          value={state}
        />

        <Label>City</Label>
        <SelectInput
          onChange={setCity}
          type="CITY"
          country={country}
          state={state}
          placeholder={"Select City"}
          value={city}
        />

        <CustomToggle name="Hireable" value={hireable} onChange={setHireable} />
        <CustomToggle
          name="Gender"
          options={[
            { label: "Male", value: "MALE" },
            { label: "Female", value: "FEMALE" },
          ]}
          value={gender}
          onChange={setGender}
        />
        <CustomToggle
          name="Privacy"
          options={[
            { label: "Public", value: "PUBLIC" },
            { label: "Private", value: "PRIVATE" },
          ]}
          value={privacy}
          onChange={setPrivacy}
        />
      </Inputs>

      <SubmitButton onClick={save}>Save</SubmitButton>
    </>
  );

  function isValidLink(url) {
    var re =
      /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
    return re.test(url);
  }

  function chooseImage() {
    selectFile({ onlyImage: true }).then(setSelectedImages);
  }

  async function uploadImage() {
    if (!selectedImages) return null;
    if (!selectedImages.length) return null;
    setLoading(true);
    console.log("Uploading Image");
    let newFile = await compressAndUploadFile(null, selectedImages[0]);
    setLoading(false);
    return newFile;
  }

  async function save() {
    if (!bio) return alert.show("Bio is missing");
    let fileData = await uploadImage();

    if (websiteLink) {
      if (!isValidLink(websiteLink)) {
        return alert.show("Invalid website link");
      }
    }

    let toSend = {
      changes: {
        onboarding: "basicInfo",
        bio,
        githubHandle,
        privacy,
        websiteLink,
        hireable,
        country,
        state,
        city,
        gender,
        username,
      },
    };

    if (fileData) {
      if (fileData.fileName) {
        toSend.changes.profileImage = {
          type: "S3_UPLOAD",
          data: fileData.fileName,
        };
      }
    }

    setLoading(true);

    try {
      await serverLine.patch("/profile", toSend);

      let newLoggedInUser = { ...loggedInUser };

      newLoggedInUser.username = username;
      if (newLoggedInUser.onboarding) {
        newLoggedInUser.onboarding.basicInfo = true;
      } else {
        newLoggedInUser.onboarding = { basicInfo: true };
      }

      setLoggedInUser(newLoggedInUser);

      setLoading(false);
      if (callback) callback(username);
      alert.show("Saved!");
    } catch (e) {
      setLoading(false);
      alert.show(e.message);
    }
  }
}

function ShowSelectedImage({ fileObject }) {
  const [src, setSrc] = useState(null);

  useEffect(() => {
    var fr = new FileReader();
    fr.onload = function (e) {
      setSrc(this.result);
    };
    fr.readAsDataURL(fileObject);
  }, [fileObject]);

  if (!fileObject) return;
  if (!src) return null;
  return <SelectedImage src={src} />;
}

const SelectInputContainer = styled.div`
  display: flex;
  width: 38vw;
  padding: 15px;
  cursor: pointer;
  border-radius: 15px;
  background: var(--translucent);
  justify-content: space-between;
  @media (max-width: 900px) {
    width: 90vw;
  }
`;
const SelectInputText = styled.div``;
const SelectInputButton = styled.div``;

function SelectInput({
  value,
  placeholder,
  onChange,
  options,
  type,
  country,
  state,
}) {
  const { setForm } = useContext(Context);

  return (
    <SelectInputContainer
      onClick={() => {
        setForm({
          title: placeholder,
          component: (
            <PopupLocationSelector
              state={state}
              country={country}
              type={type}
              value={value}
              options={options}
              onChange={onChange}
            />
          ),
        });
      }}
    >
      <SelectInputText>{value ? value : placeholder}</SelectInputText>

      <SelectInputButton>
        <AiOutlineDown />
      </SelectInputButton>
    </SelectInputContainer>
  );
}
