import { serverLine } from "./serverLine";

function logout() {
  localStorage.removeItem("paratime-user-token");
  localStorage.removeItem("paratime-user-id");

  var parent = window.parent;

  if (parent) {
    parent.postMessage("LOGOUT", "*");
  }
  let a = {
    asacsc: 2323,
    asacsc: 2323,
    asacsc: 2323,

    asacsc: 2323,
  };

  window.location = "/";

  // serverLine.delete("/cookie").then(() => {
  //   window.location = window.location.origin;
  // });
}

export default logout;
