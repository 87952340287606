import WithHeader from "./WithHeader";
import { FiSearch } from "react-icons/fi";
import { serverLine } from "../controllers/serverLine";
import LoadingSection from "./LoadingSection";

import styled from "styled-components";
import { useContext, useState } from "react";
import { useEffect } from "react";
import Link from "./Link";
import FollowButton from "./FollowButton";
import Context from "../Context";
import virtualProducts from "../data/virtualProducts";
import productionProducts from "../data/productionProducts";
import getDateWithSlash from "../controllers/getDateWithSlash";
import getCurrentPackage from "../controllers/getCurrentPackage";
import { useNavigate } from "react-router-dom";
import getPackageExpiry from "../controllers/getPackageExpiry";


const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 25px;
`;


const Title = styled.div`
  // text-align:center;
  width:100%;
  opacity:0.5;
`


const ProductCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding:25px;
  border-radius:15px;
  border: 1px solid var(--translucentHard);
  gap:15px;
`
const ProductTop = styled.div`
  display:flex;
  flex-direction:row;
  gap:15px;
  font-size:25px;
  align-items:center;

`
const ProductTitle = styled.div`
  font-weight: 900;

`
const ProductSubTitle = styled.div`
  font-weight:  300;
  opacity:  0.5;
`
const Description = styled.div`
  display:flex;
  flex-direction:column;
  gap:5px;
  margin: 15px 0;
`


const UpgradeButton = styled.div`
  width:150px;
  background:var(--color);
  color: var(--bgColor);
  padding:15px;
  display:flex;
  justify-content:center;
  align-items:center;
  border-radius:15px;
  cursor:pointer;
  transition:0.15s ease-in;

  &:hover{
    transform: scale(0.9);
    
  }
`

const Buttons = styled.div`
  display: flex;
  flex-direction:row;
  flex-wrap:wrap;
  gap:20px;
`

const Button = styled.div`
  cursor: pointer;
  /* flex: 1; */
  padding: 15px 15px;
  background: var(--translucent);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background: var(--color);
    color: var(--bgColor);
  }
`


export default function CurrentPackage() {

  const { loggedInUserID ,loggedInUser} = useContext(Context);
  
  const navigate = useNavigate()

 let packageData = getCurrentPackage(loggedInUser)

 if(!packageData) return null

 let [currentPackage,thePayment,expiryDate] = packageData

  if(!currentPackage) return null

  let content = null

  console.log(expiryDate)


  if(currentPackage.name === "Silver") content = <ProductCard>
  <ProductTitle> Silver Package has been purchased </ProductTitle>
        <ProductSubTitle> Validity Ends on {expiryDate}</ProductSubTitle>
  </ProductCard>

  if(currentPackage.name === "Gold") content =  <ProductCard>
<ProductTitle> Gold Package has been purchased </ProductTitle>
      <ProductSubTitle> Validity Ends on {expiryDate}</ProductSubTitle>
</ProductCard>



  return <Container>
    {content}

    <Buttons>
      <Button onClick={refresh}> Refresh </Button>
      <Button onClick={changePlan} > Change Plan </Button>
    </Buttons>
  </Container>

  function changePlan(){
    navigate("/new-plan")
  }

  function refresh(){
    window.location.reload()
  }


}


