import { useContext, useEffect, useState } from "react";
import { styled } from "styled-components";
import Context from "../../Context";
import SelectUserBox from "./SelectUserBox";



const Container = styled.div`
padding:25px;
display: flex;
flex-direction:column;
gap:50px;
`

const TopPart = styled.div`
 display: flex;
 flex-direction:row;
 justify-content: space-between;
 align-items: center;
`;

const DoneButton = styled.div`
 padding: 10px 20px;
  border: 1px solid var(--translucentHard);
  color: var(--color);
  background: transparent;
  font-size: 15px;
  cursor: pointer;
  border-radius: 10px;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 10px;
  transition: 0.25s ease-out;
  &:hover {
    transform: scale(0.9);
  }
`;

const List = styled.div`
  display: flex;
  flex-direction:column;
  gap:15px;
`

const Title = styled.div`
font-weight: 100;
font-size: 25px;
`


export default function SelectVs({
  onDone,rawData
}) {

  const {loggedInUserID, setForm, showAlert} = useContext(Context)

  const [selectedPeoples, setSelectedPeoples] = useState([])


  useEffect(()=>{
    let localStorageSelection = localStorage.getItem('paratime-savedVs');
    if(localStorageSelection){
      setSelectedPeoples(JSON.parse(localStorageSelection))
    }
  },[])


  return <Container>

    <TopPart>
      <Title>Choose Vs</Title>
      <DoneButton onClick={()=>{

        if(selectedPeoples.length > 4){
          return showAlert("You can't select more than 4")
        }

        onDone(selectedPeoples)
        setForm(null)
      }}>Done</DoneButton>
    </TopPart>

    <List>
      {renderPeoples()}
    </List>
  </Container>


  function renderPeoples() {
    let toReturn = [];

    let compt = []
    for (let userID in rawData) {
      let usr = rawData[userID];
      if (userID !== loggedInUserID) {
        compt.push({ ...usr.userData });
      }
    }

    compt.sort((a, b) => {
      return b.dailyAvgScore - a.dailyAvgScore;
    });

    for(let item of compt){
      toReturn.push(<SelectUserBox userdata={item} list={selectedPeoples} setList={setList} />)
    }

    return toReturn;
  }

  function setList(newData){
    localStorage.setItem("paratime-savedVs", JSON.stringify(newData))
    setSelectedPeoples(newData)
  }

}


