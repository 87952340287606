import ExtensionWaitingForPurchase from "../components/ExtensionWaitingForPurchase";
import getUpgradeLink from "./getUpgradeLink";
import sendMessageOutsideIframe from "./sendMessageOutsideIframe";

export default function upgradeExtension(setForm){

    sendMessageOutsideIframe('OPEN_LINK',getUpgradeLink())


    setForm({component: <ExtensionWaitingForPurchase/>})

}