import copy from "copy-to-clipboard";
import sendMessageOutsideIframe from "./sendMessageOutsideIframe";

export default function openLink(theLink) {
  return () => {

    if(theLink.indexOf("http") == -1){
      theLink = "https://"+theLink
    }

    let isInsideExtension = localStorage.getItem("extension-login");

    if (isInsideExtension) {

      sendMessageOutsideIframe('OPEN_LINK', theLink);
      
      // copy(theLink);
      // window.doAlert("Link Copied To Clipboard");
    } else {
      window.open(theLink);
    }
  };
}
