import Link from "../Link";
import styled, { keyframes } from "styled-components";

import toMinsOrHours from "../../controllers/toMinOrHours";

import shadeColor from "../../controllers/shadeColor";
import GraphDurationSelect from "./GraphDurationSelect";
import { useContext, useEffect, useState } from "react";
import GraphDurationPaginator from "./GraphDurationPaginator";
import getDateRange from "../../controllers/statLibrary/getDateRange";

import getXAxisForHozGraph from "../../controllers/statLibrary/getXAxisForHozGraph";

import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

import shortenTheName from "../../controllers/shortenTheName";
import SelectVs from "./SelectVs";
import Context from "../../Context";
import { RiSettingsLine } from "react-icons/ri";
import { HiOutlineDotsHorizontal } from "react-icons/hi";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Padding = styled.div`
  width: 100%;
`;

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-bottom: 25px;

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
    width: 100%;
  }
`;

const TitleDiv = styled.div`
  font-size: 18px;
  display: flex;
  /* opacity: 0.8; */
  font-weight: 300;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: flex-end; */
  gap: 0;
  padding: 0;
  background-color: var(--translucent);
  border-radius: 15px;
  position: relative;
  gap: 15px;
  padding: 15px;
`;

const Item = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;

  align-items: center;
  gap: 5px;
`;

const YAxis = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  opacity: 0.3;
  gap: 15px;
  justify-content: space-between;
  position: absolute;
  left: 13px;
  height: 82%;
  top: 20px;
`;

const scaleBar = (height) => keyframes`
    0% {
        height : 0; 
    }
    100% {
      height : ${height};
    }
`;

const ColorfulBar = styled.div`
  width: 60px;
  background: steelblue;
  border-radius: 25px 25px 5px 5px;
  animation: ${(props) => scaleBar(props.height)} 0.25s ease-out;
  min-height: 5px;
  width: ${({ dataLength }) => {
    let gap = 5;
    return `calc((38vw - 50px - ${dataLength * gap}px) / ${dataLength} )`;
  }};

  @media (max-width: 1200px) {
    width: ${({ dataLength }) => {
      let gap = 7;
      if (dataLength >= 30) gap = 1;
      return `calc((62vw - 100px - ${dataLength * gap}px) / ${dataLength} )`;
    }};
  }

  @media (max-width: 700px) {
    width: ${({ dataLength }) => {
      let screenWidth = "90vw";

      let gap = 7;
      if (dataLength >= 30) {
        gap = 0;
        screenWidth = "150vw";
      }

      if (localStorage.getItem("extension-login")) screenWidth = "100vw";
      return `calc((${screenWidth} - 60px - ${
        dataLength * gap
      }px) / ${dataLength} )`;
    }};
  }
`;

const TopLabel = styled.div`
  opacity: 0.5;
`;

const BottomLabel = styled.div`
  opacity: 0.3;
  height: 10px;
  font-size: 13px;
  margin-bottom: 5px;

  @media (max-width: 900px) {
    /* font-size: 10px; */
  }
`;

const AxisData = styled.div`
  font-size: 13px;
`;

const AxisUnit = styled.div`
  position: absolute;
  bottom: -25px;
  font-size: 13px;
`;

const Actions = styled.div`
  display: flex;
  gap: 15px;
`;

const Message = styled.div``;

const selectOptions = [
  { value: "WEEK", label: "Week" },
  { value: "MONTH", label: "Month" },
  { value: "YEAR", label: "Year" },
  { value: "EVER", label: "All Time" },
];

const labels = ["January", "February", "March", "April", "May", "June", "July"];

let scales = {
  y: {
    title: {
      display: true,
      text: "Hours",
    },
  },
};

const options = {
  // scales,
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
  },
};

const data = {
  labels,

  datasets: [],
};

const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const LabelText = styled.div`
  opacity: 0.5;
  font-size: 13px;
`;

const Colors = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  font-size: 15px;
  justify-content: flex-end;
  width: 90%;
  flex-wrap: wrap;
`;

const Color = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  flex-wrap: wrap;
  align-items: center;
`;

const ColorBox = styled.div`
  height: 10px;

  width: 10px;
  border-radius: 50px;
`;

const ColorText = styled.div`
  opacity: 0.7;
`;

const SelectVsButton = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 15px;
  gap: 0;
  border: none;
  background: transparent;
  border-radius: 15px;
  padding: 0 15px;
  height: 40px;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  background: var(--translucent);

  &:hover {
    background: var(--translucentHard);
  }

  @media (max-width: 900px) {
    display: none;
  }
`;

const TitleText = styled.div``;

const TitleButton = styled.div`
  cursor: pointer;
  opacity: 0.8;
  @media (min-width: 900px) {
    display: none;
  }
`;

export default function LineGraph({
  dataProcessor = () => {},
  title = "Demo Stat",
  rawData,
}) {
  const [timeSpan, setTimeSpan] = useState({ value: "WEEK", label: "Week" });
  const [pagination, setPagination] = useState(0);
  const { setForm } = useContext(Context);
  const [selectedPeoples, setSelectedPeoples] = useState([]);

  useEffect(() => {
    let localStorageSelection = localStorage.getItem("paratime-savedVs");
    if (localStorageSelection) {
      setSelectedPeoples(JSON.parse(localStorageSelection));
    }
  }, []);

  if (!rawData) return <Message>No data to show</Message>;

  let dateRange = getDateRange({ timeSpanType: timeSpan.value, pagination });
  let xAxis = getXAxisForHozGraph(dateRange);
  let processedData = dataProcessor({
    timeSpan,
    xAxis,
    rawData,
    selectedPeoples,
  });

  data.datasets = processedData;
  data.labels = xAxis.map((itm) => itm.label);

  return (
    <Padding>
      <TitleRow>
        <TitleDiv>
          <TitleText>{title}</TitleText>
          <TitleButton onClick={openVsSelector}>
            <HiOutlineDotsHorizontal />
          </TitleButton>
        </TitleDiv>
        <Actions>
          <GraphDurationPaginator
            rangeLabel={dateRange.rangeLabel}
            setValue={setPagination}
            value={pagination}
          />

          <GraphDurationSelect
            options={selectOptions}
            value={timeSpan}
            setValue={(val) => {
              setTimeSpan(val);
              setPagination(0);
            }}
          />
          <SelectVsButton onClick={openVsSelector}>
            <RiSettingsLine />
          </SelectVsButton>
        </Actions>
      </TitleRow>

      <Main>
        <TopRow>
          <LabelText>Hrs</LabelText>
          <Colors>{renderColors()}</Colors>
        </TopRow>
        <Line options={options} data={data} />
      </Main>
    </Padding>
  );

  function openVsSelector() {
    setForm({
      component: <SelectVs onDone={setSelectedPeoples} rawData={rawData} />,
    });
  }

  function renderColors() {
    let colors = [];

    for (let item of processedData) {
      let label = shortenTheName(item.label, 15);

      colors.push(
        <Color>
          <ColorBox style={{ backgroundColor: item.backgroundColor }} />
          <ColorText>{label}</ColorText>
        </Color>
      );
    }

    return colors;
  }
}
